import { defineStore } from 'pinia'

export interface StartDebugPhaseState {
  isActive: boolean,
  markPosition: number
}

const initialState = (): StartDebugPhaseState => ({
  isActive: false,
  markPosition: 0
})

export const startDebugPhaseState = defineStore('startDebugPhaseState', {
  state: initialState,
})
