<template>
  <div
    v-if="gamePhaseState.showRecords"
    class="boxes-records"
    :style="getStyle"
  >
    <div
    >
      <record-box
        :yellow-text="worldRecord"
        :is-scaled="false"
        blue-text="WB"
      />
      <record-box
        v-if="showPersonalBest"
        :yellow-text="personalBest"
        :is-scaled="false"
        blue-text="PB"
        class="second-box"
      />
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  RecordBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import {
  playersManager,
  timeManager
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import {
  boatStatsState,
  gamePhaseState
} from '@/stores'

export default defineComponent({
  name: 'BoxesRecords',
  components: {
    RecordBox,
  },
  mixins: [WindowAspect],
  data() {

    return {
      boatStatsState: boatStatsState(),
      gamePhaseState: gamePhaseState(),
    }

  },
  computed: {
    worldRecord() {

      return timeManager.getTimeInFormatFromSeconds(endManager.worldRecord, 3)

    },
    personalBest() {

      return timeManager.getTimeInFormatFromSeconds(playersManager.getPlayer().personalBest, 3)

    },
    showPersonalBest() {

      return playersManager.getPlayer().personalBest > 0

    },
    getStyle() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': 'left top'
      }

    }
  }
})
</script>

<style lang="less">
  .boxes-records {
    position: absolute;
    left: 1%;
    top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 143px;

    .record-box-ssm{
      .yellow-part {
        width: 170px;
      }
    }
  }
</style>
