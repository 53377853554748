/**
 * Konfig pre opponenta/ov
 */
export const opponentConfig = {

  /** pocty framov medzi ktorymi je random delay startu opponenta */
  startFrames: {

    from: 4,
    to: 7

  },

  /** hodnoty pre speedbar */
  speedbar: {

    /** kolko hodnot speedbaru sa bude generovat */
    valuesToGenerate: 85,

    /** gauss configy podla skillov */
    gauss1: {
      min: 0,
      max: 8,
      center: 1,
      dev: 3,
    },
    gauss2: {
      min: 0,
      max: 6,
      center: 1,
      dev: 2,
      minAttributeDiff: 30,
    },
    gauss3: {
      min: 0,
      max: 5,
      center: 0,
      dev: 2,
      minAttributeDiff: 60,
    },
    gauss4: {
      min: 0,
      max: 4,
      center: 0,
      dev: 1,
      minAttributeDiff: 100,
    },

  },

  /** hodnoty pre lunge */
  lunge: {

    /** hodnoty v metroch (odratane od ciela), kedy sa ma davat lunge superov */
    metersBeforeFinish: {

      max: 6,
      min: 2

    }

  }

}
