import {
  playersManager,
  settings,
  SettingsTypes,
  TimeManager,
  timeManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import { DisciplinePhases } from '../types'
import {
  speedmeterState,
  timeState
} from '@/stores'
import {
  fpsLookerState,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'

export interface FpsDataStore {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public getFpsStarted(): boolean {

    return fpsLookerState().isStarted ?? false

  }

  public setFpsData(fpsData: FpsDataStore): void {

    fpsLookerState().$patch({
      actualAverageFps: fpsData.actualAverageFps,
      actualFps: fpsData.actualFps,
      averageFps: fpsData.averageFps
    })

  }

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  public setUpdateTimeState(): void {

    let time = '0.0'
    if (disciplinePhasesManager.actualPhase >= DisciplinePhases.finish) {

      time = TimeManager.getTimeInSecondsLikeFormatted(playersManager.getPlayer().resultsArr?.[0].main || 0, 3)

    } else {

      time = timeManager.getGameTimeWithPenaltyInFormat(1)

    }
    timeState().time = time

  }

  public hideSpeedVisibility(): void {

    speedmeterState().visible = false

  }

  public updateSpeedData(speedData: SpeedData): void {

    speedmeterState().$patch({
      speed: Number(speedData.speed),
      visible: speedData.visible
    })

  }

}

export const stateManager = new StateManager()
