import { defineStore } from 'pinia'

export interface LapPositionState {
  position: string,
  showPosition: boolean,
  playerOffset: number
}

const initialState = (): LapPositionState => ({
  position: '',
  showPosition: false,
  playerOffset: 0
})

export const lapPositionState = defineStore('lapPositionState', {
  state: initialState,
})
