import {
  minigameConfig,
  PlayerRace,
  PlayerSex,
  type PlayerInfo
} from '@powerplay/core-minigames'

/**
 * Fake superi pre tutorial, trening a dennu ligu
 */

export const fakeOpponents: PlayerInfo[] = [
  {
    uuid: 'uuid1',
    name: 'Jens',
    country: 'DEU',
    countryString: 'DEU',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid2',
    name: 'Katy',
    country: 'HUN',
    countryString: 'HUN',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid3',
    name: 'Carlos',
    country: 'ESP',
    countryString: 'ESP',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid4',
    name: 'Victoria',
    country: 'NZL',
    countryString: 'NZL',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid5',
    name: 'Josef',
    country: 'CZE',
    countryString: 'CZE',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid6',
    name: 'Anna',
    country: 'POL',
    countryString: 'POL',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid7',
    name: 'Andrej',
    country: 'SVK',
    countryString: 'SVK',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
]

/**
 * attribute strength pre superov - trening
 */
export const trainingOpponentsAttributeStrengths = [
  0.89, 0.88, 0.87, 0.86, 0.85, 0.84, 0.83
]

/**
 * attribute strength pre superov - denna liga
 */
export const dailyLeagueOpponentsAttributeStrengths = [
  0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9
]

