import { defineStore } from 'pinia'

export interface SpeedmeterState {
  visible: boolean,
  speed: number
}

const initialState = (): SpeedmeterState => ({
  visible: false,
  speed: 0
})

export const speedmeterState = defineStore('speedmeterState', {
  state: initialState,
})
