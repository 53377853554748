
/**
 * Konfig pre kameru pre training
 */
export const tutorialConfig = {

  opponent: {

    /** reakcny cas vo framoch */
    reactionTime: 9,

    /** default hodnota pre generovanie */
    valueDefault: 200,

    /** specialna hodnota pre generovanie */
    valueSpecial: {

      from: 203,
      to: 203,

      /** Kazda x-ta hodnota bude specialna */
      everyX: 2

    },

    /** Kazdy x-ty frame sa bude menit hodnota speedbaru */
    changeValueEveryX: 6

  },

  /** kedy ma dat hracovi lunge */
  autoLungeMeters: 5,



}
