import {
  game,
  THREE,
  CSS2DRenderer,
  CSS2DObject,
  cameraManager,
  gsap
} from '@powerplay/core-minigames'
import { player } from './Player'
import { notesConfig } from '@/app/config'
import { WorldEnvLinesCreator } from '../../env/WorldEnvLinesCreator'
import {
  DisciplinePhases,
  NoteColors,
  Sides
} from '@/app/types'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

/**
 * Manager pre input notes
 */
export class PlayerNotesManager {

  /** lave kruznice */
  private leftOuterNotes: THREE.Mesh[] = []
  /** lave kruhy */
  private leftInnerNotes: THREE.Mesh[] = []
  /** prave kruznice */
  private rightOuterNotes: THREE.Mesh[] = []
  /** prave kruhy */
  private rightInnerNotes: THREE.Mesh[] = []
  /** lava ciara */
  private leftLine?: THREE.Line
  /** prava ciara */
  private rightLine?: THREE.Line

  /** pozicie na ciare */
  private leftPoints: THREE.Vector3[] = []
  private rightPoints: THREE.Vector3[] = []

  /** ci je viditelne */
  private visible = true

  /** farby */
  private leftColor = notesConfig.colors.default
  private rightColor = notesConfig.colors.default

  /** renderer */
  private labelRenderer = new CSS2DRenderer()

  /** labels */
  private leftLabels: CSS2DObject[] = []
  private rightLabels: CSS2DObject[] = []

  /** timeline pre kruhy outer */
  private timelineOuterNotes!: gsap.core.Timeline

  /**
   * Konstruktor
   */
  public constructor() {

    const { linePoints, lineOffset } = notesConfig

    this.leftPoints = [
      new THREE.Vector3(-linePoints[0].x, linePoints[0].y, linePoints[0].z),
      new THREE.Vector3(-linePoints[1].x, linePoints[1].y, linePoints[1].z)
    ]
    this.rightPoints = [
      new THREE.Vector3(linePoints[0].x, linePoints[0].y, linePoints[0].z),
      new THREE.Vector3(linePoints[1].x, linePoints[1].y, linePoints[1].z)
    ]

    const pointsLeft = []
    pointsLeft.push(WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      -2
    ))
    pointsLeft.push(this.leftPoints[1])

    const geometryLeft = new THREE.BufferGeometry().setFromPoints( pointsLeft )
    const pointsRight = []
    pointsRight.push(WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      -2
    ))
    pointsRight.push(this.rightPoints[1])

    const geometryRight = new THREE.BufferGeometry().setFromPoints( pointsRight )
    const material = new THREE.LineBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.3
    })

    this.leftLine = new THREE.Line(geometryLeft, material)
    this.leftLine.matrixAutoUpdate = true
    this.leftLine.position.set(-lineOffset.x, lineOffset.y - 0.015, lineOffset.z)
    this.rightLine = new THREE.Line(geometryRight, material)
    this.rightLine.matrixAutoUpdate = true
    this.rightLine.position.set(lineOffset.x, lineOffset.y - 0.015, lineOffset.z)

  }

  /**
   * Setup
   */
  public setUp(): void {

    const noteOuter = game.getMesh('notes_K1_Note_Outer')
    const noteInner = game.getMesh('notes_K1_Note_Inner')
    const { lineOffset } = notesConfig
    const point1 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      0.5
    )
    const leftOuterNote = noteOuter.clone()
    leftOuterNote.name += '_cloned'
    leftOuterNote.renderOrder = 4
    const noteOuterMaterial = noteOuter.material as THREE.MeshBasicMaterial
    leftOuterNote.material = noteOuterMaterial.clone()
    leftOuterNote.rotation.set(-0.05, 0, 0.05)
    leftOuterNote.matrixAutoUpdate = true
    leftOuterNote.position.set(-lineOffset.x + point1.x, lineOffset.y, lineOffset.z + point1.z)
    leftOuterNote.layers.enableAll()

    const point2 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      0.5
    )
    const rightOuterNote = noteOuter
    rightOuterNote.material = noteOuterMaterial.clone()
    rightOuterNote.renderOrder = 4
    rightOuterNote.rotation.set(-0.05, 0, -0.05)
    rightOuterNote.matrixAutoUpdate = true
    rightOuterNote.position.set(lineOffset.x + point2.x, lineOffset.y, lineOffset.z + point2.z)
    rightOuterNote.layers.enableAll()

    const leftOuterNoteMaterial = leftOuterNote.material as THREE.MeshBasicMaterial
    leftOuterNoteMaterial.color.set(notesConfig.colors.white)
    const rightOuterNoteMaterial = rightOuterNote.material as THREE.MeshBasicMaterial
    rightOuterNoteMaterial.color.set(notesConfig.colors.white)

    this.leftOuterNotes.push(leftOuterNote)
    this.rightOuterNotes.push(rightOuterNote)


    for (let i = 0; i < 2; i++) {

      const leftInnerNote = noteInner.clone()
      const noteInnerMaterial = noteOuter.material as THREE.MeshBasicMaterial
      leftInnerNote.material = noteInnerMaterial.clone()
      leftInnerNote.rotation.set(-0.05, 0, 0.05)
      leftInnerNote.matrixAutoUpdate = true
      leftInnerNote.position.set(-lineOffset.x, lineOffset.y, lineOffset.z)
      leftInnerNote.renderOrder = 5

      const rightInnerNote = noteInner.clone()
      rightInnerNote.material = noteInnerMaterial.clone()
      rightInnerNote.rotation.set(-0.05, 0, -0.05)
      rightInnerNote.matrixAutoUpdate = true
      rightInnerNote.position.set(lineOffset.x + point1.x, lineOffset.y, lineOffset.z)
      rightInnerNote.renderOrder = 5

      const leftInnerNoteMaterial = leftInnerNote.material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(notesConfig.colors.default)
      const rightInnerNoteMaterial = rightInnerNote.material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(notesConfig.colors.default)

      this.leftInnerNotes.push(leftInnerNote)
      this.rightInnerNotes.push(rightInnerNote)

    }

    player.athleteObject.add(...this.leftInnerNotes)
    player.athleteObject.add(...this.rightInnerNotes)
    player.athleteObject.add(...this.leftOuterNotes)
    player.athleteObject.add(...this.rightOuterNotes)
    if (this.leftLine) {

      player.athleteObject.add(this.leftLine)

    }
    if (this.rightLine) {

      player.athleteObject.add(this.rightLine)

    }

    player.playerNotesManager.setVisibilityOfAll(false)
    this.prepareLabels()
    this.prepareBottomHud()
    this.prepareStartArrow()

    this.labelRenderer.render(game.scene, cameraManager.getMainCamera())

  }

  /**
   * Nastavenie labels
   */
  private prepareLabels(): void {

    const size = new THREE.Vector2()
    game.renderManager.renderer.getSize(size)
    const elementGame = document.getElementById('game-container')

    this.labelRenderer.setSize(
      elementGame?.clientWidth ?? size.x,
      elementGame?.clientHeight ?? size.y
    )
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.labelRenderer.domElement.style.position = 'fixed'
    this.labelRenderer.domElement.style.top = '0px'
    this.labelRenderer.domElement.style.left = '0px'
    document.getElementById('main-ui')?.appendChild( this.labelRenderer.domElement )

    const labelSides = [Sides.LEFT, Sides.RIGHT]
    labelSides.forEach((side: Sides) => {

      notesConfig.labels.forEach((labelName) => {

        const divOuter = document.createElement('div')
        divOuter.className = 'label'
        const divInner = document.createElement('div')
        Object.assign(
          divInner.style,
          notesConfig.labelStyle[side]
        )
        divInner.style.backgroundImage =
      `url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/hlaska_${
        labelName
      }.png')`
        divInner.style.transform = `scale(${window.minigameScaleCoef})`
        divInner.style.marginLeft = `${notesConfig.labelStyle[side].marginLeft * window.minigameScaleCoef }%`
        divOuter.appendChild(divInner)

        const label = new CSS2DObject( divOuter )
        label.position.set( 0, 0, 0 )
        label.uuid = labelName
        label.visible = false

        if (side === Sides.RIGHT) {

          player.playerNotesManager.rightOuterNotes[0].add( label )
          this.rightLabels.push(label)

        } else {

          player.playerNotesManager.leftOuterNotes[0].add( label )
          this.leftLabels.push(label)

        }

      })

    })

  }

  private prepareStartArrow(): void {

    const divStartArrowR = document.createElement('div')
    divStartArrowR.className = 'label'
    divStartArrowR.id = 'start-arrow-teleport-right'
    const labelR = new CSS2DObject( divStartArrowR )
    labelR.position.set( 0, 0, 0 )
    const divStartArrowL = document.createElement('div')
    divStartArrowL.className = 'label'
    divStartArrowL.id = 'start-arrow-teleport-left'
    const labelL = new CSS2DObject( divStartArrowL )
    labelL.position.set( 0, 0, 0 )


    player.playerNotesManager.rightOuterNotes[0].add( labelR )
    player.playerNotesManager.leftOuterNotes[0].add( labelL )


  }

  /**
   * Nastavenie bottom hud div
   */
  private prepareBottomHud(): void {

    const divBottomHud = document.createElement('div')
    divBottomHud.className = 'label'
    divBottomHud.id = 'bottom-hud-teleport'
    const label = new CSS2DObject( divBottomHud )
    label.position.set( 0, 0, -2.5 )
    player.athleteObject.add(label)

  }

  /**
   * Spravenie efektu po inpute na outer kruhoch
   * @param side - Strana
   */
  public afterInputEffect(side: Sides | undefined): void {

    if (side === undefined) return

    const objectToScale = side === Sides.LEFT ? this.leftOuterNotes[0] : this.rightOuterNotes[0]
    const scale = 1.1

    if (this.timelineOuterNotes) this.timelineOuterNotes.kill()
    objectToScale.scale.set(1, 1, 1)

    this.timelineOuterNotes = gsap.timeline()
      .to(
        objectToScale.scale,
        {
          x: scale,
          y: scale,
          z: scale,
          duration: 0.05,
          ease: 'power1.out'
        }
      ).to(
        objectToScale.scale,
        {
          x: 1,
          y: 1,
          z: 1,
          delay: 0.05,
          duration: 0.05,
          ease: 'power1.out'
        }
      )


  }

  /**
   * Update
   */
  public update(): void {

    if (!this.visible) return

    let leftAdder = 0
    let rightAdder = 0
    let leftColor = this.leftColor
    let rightColor = this.rightColor
    let leftColorOuter = notesConfig.colors.white
    let rightColorOuter = notesConfig.colors.white

    const percent = 100 / (player.velocityManager.paddleFrequency) * (player.velocityManager.intervalFrames + 1)
    let percent1 = percent
    let percent2 = percent
    let percent3 = percent
    let percent4 = percent
    const indexColor = player.velocityManager.qualityText as NoteColors
    const color = notesConfig.colors[indexColor] ?? notesConfig.colors.default

    if (player.velocityManager.activeSide === Sides.RIGHT) {

      if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.start) {

        rightColor = color
        rightColorOuter = color === notesConfig.colors.default ? notesConfig.colors.white : color

      } else {

        percent1 = -50

      }
      leftAdder = 100

    } else {

      if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.start) {

        leftColor = color
        leftColorOuter = color === notesConfig.colors.default ? notesConfig.colors.white : color

      } else {

        percent2 = -50

      }
      rightAdder = 100

    }


    percent1 += leftAdder
    percent2 += rightAdder
    percent3 += leftAdder
    percent4 += rightAdder

    const { lineOffset } = notesConfig
    const point1 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      percent1 / 100
    )
    this.leftInnerNotes[0].position.z = lineOffset.z + point1.z
    this.leftInnerNotes[0].position.x = -lineOffset.x + point1.x
    const point2 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      percent2 / 100
    )
    this.rightInnerNotes[0].position.z = lineOffset.z + point2.z
    this.rightInnerNotes[0].position.x = lineOffset.x + point2.x

    const point3 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      (percent3 - 200) / 100
    )
    this.leftInnerNotes[1].position.z = lineOffset.z + point3.z
    this.leftInnerNotes[1].position.x = -lineOffset.x + point3.x
    const point4 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      (percent4 - 200) / 100
    )
    this.rightInnerNotes[1].position.z = lineOffset.z + point4.z
    this.rightInnerNotes[1].position.x = lineOffset.x + point4.x

    if (this.leftColor !== leftColor) {

      this.leftLabels.forEach(label => label.visible = false)
      const leftLabel = this.leftLabels.find(label => label.uuid === player.velocityManager.qualityText)
      if (leftLabel) leftLabel.visible = true

      this.leftColor = leftColor
      const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(leftColor)
      const leftOuterNoteMaterial = this.leftOuterNotes[0].material as THREE.MeshBasicMaterial
      leftOuterNoteMaterial.color.set(leftColorOuter)

      if (this.leftLine) {

        // TODO: neskor aj zmenime hrubku, potrebujeme iny material/mesh
        const materialLine = this.leftLine.material as THREE.MeshBasicMaterial
        if (leftColor === notesConfig.colors.perfect) {

          materialLine.color.set(leftColor)

        } else {

          materialLine.color.set(notesConfig.colors.white)

        }

      }

    }
    if (this.rightColor !== rightColor) {


      this.rightLabels.forEach(label => label.visible = false)
      const rightLabel = this.rightLabels.find(label => label.uuid === player.velocityManager.qualityText)
      if (rightLabel) rightLabel.visible = true

      this.rightColor = rightColor
      const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(rightColor)
      const rightOuterNoteMaterial = this.rightOuterNotes[0].material as THREE.MeshBasicMaterial
      rightOuterNoteMaterial.color.set(rightColorOuter)

      if (this.rightLine) {

        // TODO: neskor aj zmenime hrubku, potrebujeme iny material/mesh
        const materialLine = this.rightLine.material as THREE.MeshBasicMaterial
        if (leftColor === notesConfig.colors.perfect) {

          materialLine.color.set(rightColor)

        } else {

          materialLine.color.set(notesConfig.colors.white)

        }

      }

    }

  }

  /**
   * Renderovanie labelov
   */
  public renderLabels(): void {

    this.labelRenderer.render(game.scene, cameraManager.getMainCamera())

  }

  /**
   * Ukrytie vsetkych labels
   */
  public hideAllLabels(): void {

    this.labelRenderer.domElement.style.display = 'none'
    this.leftLabels.forEach(label => label.visible = false)
    this.rightLabels.forEach(label => label.visible = false)

  }

  /**
   * Nastavenie visibility pre vsetky objekty
   * @param visible - visibility
   * @param excludeInner - vynechat inner
   */
  public setVisibilityOfAll(visible: boolean, excludeInner = false): void {

    this.visible = visible
    if (!visible) {

      this.hideAllLabels()

    } else {

      this.labelRenderer.domElement.style.display = 'block'

    }
    this.leftOuterNotes.forEach(mesh => mesh.visible = visible)
    this.rightOuterNotes.forEach(mesh => mesh.visible = visible)
    if (!excludeInner) {

      this.leftInnerNotes.forEach(mesh => mesh.visible = visible)
      this.rightInnerNotes.forEach(mesh => mesh.visible = visible)

    }

    if (this.leftLine) {

      this.leftLine.visible = visible

    }
    if (this.rightLine) {

      this.rightLine.visible = visible

    }

  }

  /**
   * Nastavenie farby
   * @param color - nova farba
   */
  public setInnerColorOfStart(color: NoteColors): void {

    const colorObject = notesConfig.colors[color]
    if (player.athleteAnimationManager.animationSide === Sides.RIGHT) {

      this.rightColor = colorObject
      const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(colorObject)

    } else {

      this.leftColor = colorObject
      const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(colorObject)

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.leftColor = notesConfig.colors.default
    this.rightColor = notesConfig.colors.default

    const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
    leftInnerNoteMaterial.color.set(notesConfig.colors.default)
    const leftOuterNoteMaterial = this.leftOuterNotes[0].material as THREE.MeshBasicMaterial
    leftOuterNoteMaterial.color.set(notesConfig.colors.white)
    const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
    rightInnerNoteMaterial.color.set(notesConfig.colors.default)
    const rightOuterNoteMaterial = this.rightOuterNotes[0].material as THREE.MeshBasicMaterial
    rightOuterNoteMaterial.color.set(notesConfig.colors.white)

  }

}