<template>
  <Teleport :to="`#start-arrow-teleport-${showLeft ? 'left' : 'right'}`">
    <div
      class="start-arrow-container"
      :style="{
        transform: `scale(${scaleCoef})`,
        marginLeft: `${-75 * (showLeft ? -1 : 1) * scaleCoef }%`
      }"
    >
      <div
        v-if="showLeft"
        class="left"
        :style="{
          marginLeft: `${-50 * (showLeft ? -1 : 1) * scaleCoef }%`
        }"
      >
        <span class="arrow arrow-3 mirrored" />
        <span class="arrow arrow-2 mirrored" />
        <span class="arrow arrow-1 mirrored" />
        <span class="start-img" />
      </div>
      <div
        v-else
        class="right"
      >
        <span class="start-img" />
        <span class="arrow arrow-1" />
        <span class="arrow arrow-2" />
        <span class="arrow arrow-3" />
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { player } from '@/app/entities/athlete/player/Player'
import { Sides } from '@/app/types'

export default defineComponent({
  name: 'BottomHUD',
  mixins: [WindowAspect],
  computed: {
    showLeft() {

      return player.athleteAnimationManager.animationSide === Sides.LEFT

    }
  }
})
/* eslint-disable max-len */
</script>

<style lang="less" scoped>
.start-arrow-container {
  display: flex;
  align-items: center;
  width: 210px;
  height: 26px;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    transform-origin: left center;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    transform-origin: right center;
  }

  .start-img {
    background: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/start.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 102px;
    height: 26px;
  }
  .arrow {
    background: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 20px;
  }
  .arrow-2 {
    opacity: 0.66;
  }
  .arrow-3 {
    opacity: 0.33;
  }
  .mirrored {
    transform: scaleX(-1);
  }
}
</style>