import { player } from '../../entities/athlete/player'
import {
  AudioGroups,
  AudioNames,
  MovementInFinishPhases,
  type DisciplinePhaseManager
} from '../../types'
import { finishPhaseConfig } from '../../config'
import {
  timeManager,
  playersManager,
  fpsManager,
  modes,
  corePhasesManager,
  gsap,
  audioManager,
  TimesTypes,
  trainingManager,
  THREE
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import {
  emotionMessagesState,
  gamePhaseState,
  tableState,
  trainingState,
  uiState
} from '@/stores'
import type { Athlete } from '@/app/entities/athlete'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** Ci su skipovatelne hlasky pri konecnej emocii */
  public emotionMessagesSkippable = false

  /** Freeznutie zaznamenavania superov pri dennej lige, lebo pouzivame fake superov */
  public dailyLeagueSetResultsOpponentsFreeze = false

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** kolko framov preslo od zaciatku fazy */
  private framesInPhase = 0

  /** kolko framov preslo od zaciatku podfazy movementu */
  private framesInLocalPhase = 0

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** Aktualna faza pohybu v cieli */
  private movementInFinishPhase = MovementInFinishPhases.before

  /** pole objektov faz pohybu v cieli */
  private movementPhasesUpdates: (() => unknown)[] = []

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** ci je mozne skipnut */
  private skippable = false

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /** Kolko superov dobehlo do ciela */
  public finishedOpponents = 0

  /** Ci je hrac pripraveny skoncit automaticky */
  private playerReadyForFinish = false

  /** tween na emocii */
  private emotionTween?: gsap.core.Tween

  /** tween pre unlock skipu finalnej emocie */
  private tweenToSkipFinalEmotions?: gsap.core.Tween

  /** Ci uz su emocie hotove */
  private emotionsDone = false

  /** pomocny vektor */
  private helperVector = new THREE.Vector3()

  /** Ci mal hrac WR */
  private isWorldRecord = false

  /** Hodnota WR */
  private worldRecord = 0

  /** Finalna pozicia hraca */
  private position = 0

  /** tween na audio komentatorov */
  private commentatorsAudioDelayTween!: gsap.core.Tween


  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // nastavime fazy pohybu
    // this.movementPhasesUpdates[MovementInFinishPhases.setThings] = this.moveInFinishSetThings
    this.movementPhasesUpdates[MovementInFinishPhases.linearDecrease] = this.moveInFinishLinear
    // this.movementPhasesUpdates[MovementInFinishPhases.stay] = this.moveInFinishStay

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    if (modes.isTutorial()) {

      player.finishAction()
      tutorialFlow.finishPassed()
      return

    }

    fpsManager.pauseCounting()

    this.reset()
    this.preparePhase()

    playersManager.setStandings(3)
    console.log('STANDINGS', playersManager.getStandings())
    tableState().dataTable = playersManager.getStandings()

    console.warn('finish phase started')
    gamePhaseState().showSmallActionButtons = false

    player.finishAction()

    // this.actualSpeed = player.velocityManager.getVelocity() / fpsManager.fpsLimit
    console.log(`Finish speed ${this.actualSpeed}`)

    this.movementInFinishPhase = MovementInFinishPhases.linearDecrease

    // cameraManager.setState(CameraStates.disciplineOutro)
    // cameraManager.playTween()

    uiState().$patch({
      showTimeKeeper: true,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        uiState().$patch({
          showTimeKeeper: false,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.prepareEmotionMessages()

      }
    })

    this.commentatorsAudioDelayTween = gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        this.setFinalDataAndStats()
        this.playCommentatorAudio()

      }
    })

  }

  /**
   * Nastavenie finalnych dat a statistik
   */
  private setFinalDataAndStats(): void {

    playersManager.setStandings()
    // this.personalBest = playersManager.getPlayer().personalBest
    // if (this.personalBest === 0) this.personalBest = minigameConfig.dnfValue
    this.worldRecord = endManager.worldRecord
    this.position = playersManager.getPlayerActualPosition(player.uuid)
    // this.inTop3 = this.position <= 3
    // this.isPersonalBest = this.timeSeconds <= this.personalBest
    this.isWorldRecord = player.finalTime <= this.worldRecord

  }

  /**
   * nastavime data pre top box
   */
  public prepareEmotionMessages(): void {

    if (corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount) return

    let withEmotionMessages = false

    // UI pri emocii, ak islo o posledny pokus
    if (modes.isTrainingMode()) {

      // HIGH SCORE - TRENING
      trainingState().$patch({
        newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
        showNewHighScore: trainingManager.isNewHighScore()
      })

      this.setTimerToSkipFinalEmotions()

    } else {

      // UI veci mimo TG
      withEmotionMessages = this.showEmotionMessages()

    }

    if (withEmotionMessages) return

    this.emotionTween = gsap.to(
      {},
      {
        duration: 2.5,
        onComplete: () => {

          this.tweenToSkipFinalEmotions?.kill()
          this.afterEmotions()

        }
      }
    )

  }

  /**
   * Spravenie veci po emociach
   */
  public afterEmotions(): void {

    trainingState().showNewHighScore = false
    emotionMessagesState().showMessage = false

    this.emotionsDone = true

  }

  /**
   * Nastavenie timeru na skip final emocie
   */
  private setTimerToSkipFinalEmotions(): void {

    // dame timer na skip poslednej emocie s hlaskami
    this.tweenToSkipFinalEmotions = gsap.to({}, {
      duration: 1.5,
      onComplete: () => {

        this.emotionMessagesSkippable = true

      }
    })

  }

  /**
   * nastavime emocne message
   * @returns True, ak zobrazujeme emocnu hlasku
   */
  public showEmotionMessages(): boolean {

    playersManager.setStandings()
    const timeSeconds = player.finalTime
    const personalBest = playersManager.getPlayer().personalBest
    const position = playersManager.getPlayerActualPosition(player.uuid)
    const inTop3 = position <= 3
    const isPersonalBest = timeSeconds <= personalBest

    // ak mame nejaku hlasku/y, tak zobrazime kameru kusok inak
    if ((modes.isDailyLeague() && !playersManager.isPlayerImproved()) || (!inTop3 && !isPersonalBest)) return false

    startPhaseStateManager.hideAllTextMessages()

    let pbText = ''
    if (isPersonalBest) {

      pbText = timeSeconds < personalBest ? 'newPersonalBest' : 'personalBest'

    }

    emotionMessagesState().$patch({
      showMessage: true,
      firstLineText: inTop3 ? 'congratulations' : '',
      firstLineTextSecond: pbText,
      secondLineText: inTop3 ? position : 0,
      secondLineTextSecond: isPersonalBest ? timeManager.getTimeInFormatFromSeconds(timeSeconds, 3) : '',
    })

    // dame timer na skip poslednej emocie s hlaskami
    this.setTimerToSkipFinalEmotions()

    return true

  }

  /**
   * Ziskanie objektu atleta podla UUID
   * @param uuid - UUID
   * @returns Atlet
   */
  private getAthleteObjectByUUID(uuid: string): Athlete {

    if (player.uuid === uuid) return player

    const index = opponentsManager.getOpponentsIds().findIndex(id => id === uuid)
    return opponentsManager.getOpponents()[index]

  }

  /**
   * pustime komentatora
   */
  private playCommentatorAudio(): void {

    let audio = AudioNames.commentatorRank6
    const standings = playersManager.getStandings()
    const photoFinishTimeLimit = 0.03

    if (this.position <= 3) {

      // aby nepadla cela hra, radsej obetujeme zvuky
      if (standings[0] === undefined || standings[1] === undefined) return

      const athleteFirst = this.getAthleteObjectByUUID(standings[0].uuid)
      const athleteSecond = this.getAthleteObjectByUUID(standings[1].uuid)

      // aby nepadla cela hra, radsej obetujeme zvuky
      if (athleteFirst === undefined || athleteSecond === undefined) return

      const firstTime = athleteFirst.finalTime
      const secondTime = athleteSecond.finalTime
      let photoFinish = Math.abs(secondTime - firstTime) <= photoFinishTimeLimit

      // iba ked mame zaznam o tretom superovi, mozeme ho kontrolovat, ale nesmie byt hrac prvy
      if (standings[2] && this.position !== 1) {

        const athleteThird = this.getAthleteObjectByUUID(standings[2].uuid)
        if (athleteThird !== undefined) {

          const thirdTime = athleteThird.finalTime
          photoFinish = (photoFinish && this.position < 3) || Math.abs(thirdTime - firstTime) <= photoFinishTimeLimit

        }

      }

      const random = Math.round(Math.random()) === 0

      // ak by bol diff 0, tak
      if (photoFinish) {

        audio = random ? AudioNames.commentatorRank123 : AudioNames.commentatorRank123v2

      } else {

        audio = AudioNames.commentatorRank23
        if (this.position === 1) audio = random ? AudioNames.commentatorRank1 : AudioNames.commentatorRank1v2

      }

    } else if (this.position <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentatorRank45

    }

    // pri boss fighte a 2. mieste davame speci hlasku
    if (modes.isEventBossFight() && this.position > 1) audio = AudioNames.commentatorRank6

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * Hybanie sa v cieli - faza linearny pohyb
   */
  private moveInFinishLinear = (): void => {

    this.framesInLocalPhase++

    this.actualSpeed -= finishPhaseConfig.speedAutoDecreaseValue
    if (this.actualSpeed < 0.5) this.actualSpeed = 0.5

    if (this.framesInLocalPhase >= finishPhaseConfig.stayAtEndFrames) {

      this.playerReadyForFinish = true

    }

  }

  /**
   * Pohyb v cieli
   */
  private moveInFinish(): void {

    this.framesInPhase++

    // po x frameoch nastavujeme animaciu konca
    if (this.framesInPhase === finishPhaseConfig.startAnimationEndAfterFrames) {

      // nic?

    }

    // update aktualnej fazy
    // this.movementPhasesUpdates[this.movementInFinishPhase]?.()
    this.moveInFinishLinear()

  }

  /**
   * Kontrola a riesenie inputov
   */
  public handleInputs(): void {

    // skip na emocie
    if (this.emotionMessagesSkippable && this.allOpponentsInFinish()) {

      this.emotionTween?.kill()
      this.emotionMessagesSkippable = false
      this.afterEmotions()

      return

    }

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.moveInFinish()
    this.checkEnd()

  }

  /**
   * Zistenie, ci su vsetci superi v cieli
   * @returns True, ak su vsetci superi v cieli
   */
  private allOpponentsInFinish(): boolean {

    return this.finishedOpponents === opponentsManager.getOpponentsCount()

  }

  /**
   * Kontrola konca
   */
  private checkEnd(): void {

    // ak je hrac uz v cieli a cakame na protihracov, aby sa uz dalo skipnut
    if (this.allOpponentsInFinish() && this.playerReadyForFinish && this.emotionsDone) {

      this.setFinishPhaseTween()

    }

  }

  /**
   * Zobrazenie finalnej tabulky
   */
  private prepareFinishTable() {

    this.generateFakeOpponentsData()
    timeManager.setActive(TimesTypes.game, false)
    // data pre tabulku
    playersManager.setStandings(3)
    console.log('STANDINGS', playersManager.getStandings())
    tableState().dataTable = playersManager.getStandings()

  }

  /**
   * Vygenerovanie casy pre hracov ktori nestihli prejst cielom
   */
  private generateFakeOpponentsData(): void {

    if (!modes.isDailyLeague()) return

    // musime nastavit, aby sa uz ziadne data nezapisovali dalsie
    this.dailyLeagueSetResultsOpponentsFreeze = true

    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
    opponentsManager.setOriginalData()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.prepareFinishTable()
    if (this.ended) return
    this.ended = true

    this.emotionMessagesSkippable = false

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()
    if (this.commentatorsAudioDelayTween) this.commentatorsAudioDelayTween.kill()

    uiState().$patch({
      showTimeKeeper: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    // resetujeme vsetky hlasky, aby nahodou neostali
    startPhaseStateManager.hideTextMessage(1)
    startPhaseStateManager.hideTextMessage(2)

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    gamePhaseState().showRecords = false

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.ended = false
    this.movementInFinishPhase = MovementInFinishPhases.before
    if (this.changeUiStateTween) this.changeUiStateTween.kill()
    if (this.commentatorsAudioDelayTween) this.commentatorsAudioDelayTween.kill()
    this.emotionMessagesSkippable = false
    this.emotionTween?.kill()
    this.emotionsDone = false

  }

}
