import { LungeStates } from '../types'
import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre lunge
 */
export const lungeConfig = {

  /** Pocet frameov pre jednotlive stavy pre lunge */
  frames: {
    [LungeStates.none]: 0,
    [LungeStates.forward]: 10,
    [LungeStates.stay]: 5,
    [LungeStates.backward]: 10,
  },

  /** pocet metrov pred icelom, kedy je dostupny lunge */
  distanceBeforeFinish: {
    min: 10,
    max: 12
  },

  /** Maximalny preklon v metroch */
  lungeOffsetMax: 3.3,

  /** Min preklon v metroch - zabezpecuje offset medzi predkom lode a pivot pointom */
  lungeOffsetMin: 2.8,

  /** coef na slowmo */
  speedLockCoefCameraChange: 0.1,

  /** coef na slowmo */
  speedLockCoefFinishLine: 0.8,

  /** offset kamery pri speedLocku */
  cameraOffset: new THREE.Vector3(6, 1.5, 0),

  /** offset pre kameru na osi z kvoli tomu, aby sa pozeral na spicku */
  cameraLookAtOffsetZ: 2.6,

  /** cas v s za aky sa presunie kamera */
  cameraTransitionDuration: 2,

}
