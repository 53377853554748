import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Kvalita startu podla GD - hodnoty su po frameoch */
  startQualities: [
    0,
    0, // 1
    0,
    100,
    99,
    98, // 5
    95,
    90,
    85,
    80,
    70, // 10
    60,
    50,
    25,
    15,
    5 // 15
  ],

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      endPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      startRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      endRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      duration: 3,
      notSkippableFrames: 20,
      fov: 36.4,
      offsetCamera: true
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(-8.52113342285156, 8.827933311462402, 800.231506347656),
      endPosition: new THREE.Vector3(-11.572793006896973, 10.278261184692383, 853.4120483398438),
      startRotation: new THREE.Vector3(1.5755526, 0.0002827, -3.0819726),
      endRotation: new THREE.Vector3(1.5415721, -0.0016686, -3.0846141),
      fov: 50.7,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(-1.7689990997314453, 0.7242684364318848, 7.31744384765625),
      endPosition: new THREE.Vector3(-1.221395492553711, 0.6708931922912598, 4.97119140625),
      startRotation: new THREE.Vector3(1.549231, -0.0050533, 0.2292245),
      endRotation: new THREE.Vector3(1.549231, -0.0050533, 0.2292245),
      duration: 3,
      fov: 65.9,
      fovEnd: 50.7,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
