import { defineStore } from 'pinia'

export interface EmotionMessagesState {
  showMessage: boolean,
  firstLineText: string,
  firstLineTextSecond: string,
  secondLineText: number,
  secondLineTextSecond: string,
}

const initialState = (): EmotionMessagesState => ({
  showMessage: false,
  firstLineText: '',
  firstLineTextSecond: '',
  secondLineText: 0,
  secondLineTextSecond: '',
})

export const emotionMessagesState = defineStore('emotionMessagesState', {
  state: initialState,
})
