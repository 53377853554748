import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  // {
  //   files: [
  //     AudioNames.spritePaddling
  //   ],
  //   startOnRandomPlace: false,
  //   randomDelayBeforeStartFrom: 0,
  //   randomDelayBeforeStartTo: 0,
  //   enabled: true,
  //   volume: 1,
  //   volumeRandom: 0,
  //   rate: 1,
  //   rateRandom: 0,
  //   loop: false,
  //   // subset: true,
  //   // randomizeSprite: true,
  //   category: AudioCategories.sprite,
  //   sprite: {
  //     'kayak_paddling_var01': [
  //       0,
  //       680.8616780045351
  //     ],
  //     'kayak_paddling_var02': [
  //       2000,
  //       744.3990929705216
  //     ],
  //     'kayak_paddling_var03': [
  //       4000,
  //       710.5668934240362
  //     ],
  //     'kayak_paddling_var04': [
  //       6000,
  //       663.1746031746034
  //     ],
  //     'kayak_paddling_var05': [
  //       8000,
  //       724.3083900226761
  //     ],
  //     'kayak_paddling_var07': [
  //       10000,
  //       667.7097505668926
  //     ],
  //     'kayak_paddling_var09': [
  //       12000,
  //       801.6099773242634
  //     ],
  //     'kayak_paddling_var10': [
  //       14000,
  //       752.6530612244891
  //     ]
  //   }
  // },
  // {
  //   files: [
  //     AudioNames.spriteAudience
  //   ],
  //   startOnRandomPlace: false,
  //   randomDelayBeforeStartFrom: 0,
  //   randomDelayBeforeStartTo: 0,
  //   enabled: true,
  //   volume: 1,
  //   volumeRandom: 0,
  //   rate: 1,
  //   rateRandom: 0,
  //   loop: true,
  //   category: AudioCategories.sprite,
  //   group: AudioGroups.audience,
  //   sprite: {
  //     '100m_hyped_overlay': [
  //       0,
  //       16626.145124716553
  //     ],
  //     'audience-bad-overlay-3': [
  //       18000,
  //       3108.5714285714303
  //     ],
  //     /* 'audience-hyped-overlay-5': [
  //       23000,
  //       15203.265306122446
  //     ],*/
  //     'audience-noise': [
  //       40000,
  //       14796.893424036283
  //     ]
  //   }
  // },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'kayak_finish_rank1_01': [
        0,
        4720.249433106576
      ],
      'kayak_finish_rank1_012': [
        6000,
        6286.485260770975
      ],
      'kayak_finish_rank1-3_01': [
        14000,
        5267.936507936508
      ],
      'kayak_finish_rank1-3_012': [
        21000,
        5810.9977324263045
      ],
      'kayak_finish_rank2-3': [
        28000,
        4904.8072562358275
      ],
      'kayak_finish_rank4-5': [
        34000,
        4037.0294784580524
      ],
      'kayak_finish_rank6p': [
        40000,
        5599.115646258504
      ],
      'kayak_halfway_rank1': [
        47000,
        6085.986394557821
      ],
      'kayak_halfway_rank2-3': [
        55000,
        6567.301587301586
      ],
      'kayak_halfway_rank4p': [
        63000,
        5221.609977324263
      ],
      'kayak_start_false_start': [
        70000,
        3792.7437641723286
      ],
      'kayak_start_good_start': [
        75000,
        4876.099773242629
      ],
      'kayak_start_perfect_start_01': [
        81000,
        3682.4036281179106
      ],
      'kayak_start_perfect_start_012': [
        86000,
        2326.371882086164
      ],
      'kayak_start_poor_start': [
        90000,
        5031.065759637187
      ],
      'kayak_start_second_false_start': [
        97000,
        5621.791383219957
      ]
    }
  }
]