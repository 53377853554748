import { defineStore } from 'pinia'
// import type { DisplayMessage } from '@/app/types'

export interface StartMessageState {
  showMessage1: boolean,
  messageText1: string,
  messageColor1: number,
  messageType1: number,
  showMessage2: boolean,
  messageText2: string,
  messageColor2: number,
  messageType2: number,
  showStartArrow: boolean
}

const initialState = (): StartMessageState => ({
  showMessage1: false,
  messageText1: '',
  messageColor1: 0,
  messageType1: 0,
  showMessage2: false,
  messageText2: '',
  messageColor2: 0,
  messageType2: 0,
  showStartArrow: false
})

export const startMessageState = defineStore('startMessageState', {
  state: initialState,
})
