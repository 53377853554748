/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteAudience = 'audience',
  spritePaddling = 'paddling',

  audienceHyped = '100m_hyped_overlay',
  audienceNoise = 'audience-noise',
  audienceBad = 'audience-bad-overlay-3',
  audienceHyped2 = 'audience-hyped-overlay-5',

  paddlingPrefix = 'kayak_paddling_var',
  paddling01 = 'kayak_paddling_var01',
  paddling02 = 'kayak_paddling_var02',
  paddling03 = 'kayak_paddling_var03',
  paddling04 = 'kayak_paddling_var04',
  paddling05 = 'kayak_paddling_var05',
  paddling07 = 'kayak_paddling_var07',
  paddling09 = 'kayak_paddling_var09',
  paddling10 = 'kayak_paddling_var10',

  falseStart = 'kayak_false_start',
  readySet = 'kayak_ready_set',
  startSignal = 'kayak_start_signal',

  commentatorFinalResults = 'kayak_final_results_01',
  commentatorFinalResults2 = 'kayak_final_results_012',
  commentatorIntro = 'kayak_intro_01',
  commentatorIntro2 = 'kayak_intro_012',

  commentatorRank1 = 'kayak_finish_rank1_01',
  commentatorRank1v2 = 'kayak_finish_rank1_012',
  commentatorRank123 = 'kayak_finish_rank1-3_01',
  commentatorRank123v2 = 'kayak_finish_rank1-3_012',
  commentatorRank23 = 'kayak_finish_rank2-3',
  commentatorRank45 = 'kayak_finish_rank4-5',
  commentatorRank6 = 'kayak_finish_rank6p',
  commentatorHalfWayRank1 = 'kayak_halfway_rank1',
  commentatorHalfwayRank23 = 'kayak_halfway_rank2-3',
  commentatorHalfwayRank4 = 'kayak_halfway_rank4p',
  commentatorFalseStart = 'kayak_start_false_start',
  commentatorStartGood = 'kayak_start_good_start',
  commentatorStartPerfect = 'kayak_start_perfect_start_01',
  commentatorStartPerfect2 = 'kayak_start_perfect_start_012',
  commentatorStartPoor = 'kayak_start_poor_start',
  commentatorSecondFalseStart = 'kayak_start_second_false_start'

}

/** Kategorie zvukov */
export enum AudioCategories {

  start = 'start',
  audience = 'audience',
  audience2 = 'audience2',
  paddling = 'paddling',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators',
  audience = 'audience'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumePlaceTypes {

  start = 'start',
  stop = 'stop'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumeTypes {

  increase = 'increase',
  decrease = 'decrease'

}

/** Typy sektorov pre hlasitost */
export enum AudienceVolumeSectorTypes {

  full = 'full',
  graduallyDecrease = 'graduallyDecrease',
  graduallyIncrease = 'graduallyIncrease',
  min = 'min'

}
