import {
  gameConfig,
  pathsConfig
} from '@/app/config'
import {
  game,
  THREE
} from '@powerplay/core-minigames'

/**
 * Lines Manager
 * Trieda spravujuca drahy po ktorych behaju hraci
 */
export class LinesManager {

  /** dostupne drahy */
  private playerPaths: THREE.CurvePath<THREE.Vector3>[] = []

  /**
   * init
   */
  public init(): void {

    const gameObjectWithLines = new THREE.Group()

    const { tracks } = pathsConfig

    for (let i = 1; i <= tracks; i++) {

      const pathName = `K1_Track_${i}`
      const trackPlayer = game.getObject3D(pathName)
      trackPlayer.visible = false
      this.playerPaths[i] = new THREE.CurvePath<THREE.Vector3>()
      this.setupCurvePathFromObject(trackPlayer as THREE.LineSegments, this.playerPaths[i])

      gameObjectWithLines.add(trackPlayer)

      game.scene.add(gameObjectWithLines)

    }

  }

  /**
   * @param index - index drahy
   * @returns - drahu, ak je validny index
   */
  public getPath(index: number): THREE.CurvePath<THREE.Vector3> | undefined {

    if (index <= 0) return undefined
    if (index >= this.playerPaths.length) return undefined

    return this.playerPaths[index]

  }

  /**
   * Vytvorenie curvePath z modelu
   * @param object - objekt s krivkou
   * @param curvePath - objekt, kde ulozime curvePath
   */
  private setupCurvePathFromObject(
    object: THREE.LineSegments,
    curvePath: THREE.CurvePath<THREE.Vector3>
  ): void {

    const pointsArray: number[] = Array.from(object.geometry.attributes.position.array)
    const coordinates: (THREE.Vector3 | undefined)[] = pointsArray
      .map((_: number, idx: number, origArray) => {

        if (idx % 3 !== 0) {

          return undefined

        }
        return new THREE.Vector3(
          origArray[idx],
          origArray[idx + 1],
          origArray[idx + 2],

        )

      }).filter(e => e !== undefined)

    if (coordinates.includes(undefined)) {

      // error
      return

    }

    const lastPoint = new THREE.Vector3()
    coordinates.forEach((point, index) => {

      if (point === undefined) return
      if (index === 0) console.log(point)
      if (index > 0) {

        curvePath.add(new THREE.LineCurve3(
          new THREE.Vector3(lastPoint.x, lastPoint.y, lastPoint.z),
          new THREE.Vector3(point.x, point.y, point.z)
        ))

      }

      lastPoint.copy(point)

    })

  }

  /**
   * Vytovrenie debug bodov podla typu triggeru
   * @param type - Typ triggera
   * @param newConfig - novy config pre zobrazenie
   */
  public debugPointsTrigger(type = 'finish', newConfig: number[] = []): void {

    let config = pathsConfig.positionsFinish
    if (type === 'start') config = pathsConfig.positionsStart
    if (newConfig.length > 0) config = newConfig

    const geometrySphere = new THREE.SphereGeometry(0.2, 0.2, 0.2)
    const materialSphere = new THREE.MeshBasicMaterial({ color: 0x00FF00 })

    this.playerPaths.forEach((value, index) => {

      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
      const position = value.getPointAt(config[index - 1])
      meshSphere.position.set(position.x, position.y, position.z)
      meshSphere.name = `${index}_${type}_debug`
      game.scene.add(meshSphere)

    })

  }

  /**
   * Vypocitanie configu podla percenta na jednej trati
   * @param percent - percento drahy
   * @param index - index drahy
   */
  public getConfigFromOnePoint(percent: number, index: number): number[] {

    const oneMetersInPercent = [] as number[]
    for (let i = 1; i < this.playerPaths.length; i++) {

      oneMetersInPercent[i - 1] = 1 / this.playerPaths[i]?.getLength() || 0

    }

    const meters = 1 / oneMetersInPercent[index] * percent
    const config = [] as number[]

    for (let i = 0; i < this.playerPaths.length - 1; i++) {

      config[i] = oneMetersInPercent[i] * meters

    }

    console.log(config)
    return config

  }

  /**
   * Debug pre jeden bod na jednej drahe
   * @param percent - percento na drahe
   * @param pathIndex - index drahy
   */
  public showPoint(percent: number, pathIndex = 3, size = 2): void {

    const geometrySphere = new THREE.SphereGeometry(size, size, size)

    const spherePosition = this.playerPaths[pathIndex].getPointAt(percent)
    const name = `${pathIndex}_point_debug`

    const sphere = game.scene.getObjectByName(name)

    if (sphere) {

      sphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
      return

    }

    const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
    const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)

    meshSphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
    meshSphere.name = name
    game.scene.add(meshSphere)


  }

  /**
   * Odstranenie debug bodov podla typu triggeru
   * @param type - Typ triggera
   */
  public removeDebugPointsTrigger(type = 'finish'): void {

    this.playerPaths.forEach((_value, index) => {

      const mesh = game.getMesh(`${index}_${type}_debug`)
      mesh.name += '_removed'
      mesh.visible = false

    })

  }

  /**
   * Getter
   * @returns playerPaths
   */
  public getPaths(): THREE.CurvePath<THREE.Vector3>[] {

    return this.playerPaths

  }

}
export const linesManager = new LinesManager()