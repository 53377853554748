<template>
  <div
    v-if="isActive"
    class="start-debug-bar"
  >
    <horizontal-bar-with-ideal
      :glow="false"
      :mark="startDebugPhaseState.markPosition"
      :gradient="barValues"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { HorizontalBarWithIdeal } from '@powerplay/core-minigames-ui-ssm'
import { startConfig } from '@/app/config'
import { startDebugPhaseState } from '@/stores'

export default defineComponent({
  name: 'StartDebugBar',
  components: {
    HorizontalBarWithIdeal
  },
  data() {

    return {
      startDebugPhaseState: startDebugPhaseState(),
    }

  },
  computed: {
    isActive() {

      return startDebugPhaseState().isActive && startConfig.debugStartBar

    },
    barValues() {

      return [0, 0, 0, 0, 0]

    }
  }
})

</script>

<style lang="less" scoped>
.start-debug-bar {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>
