import { THREE } from '@powerplay/core-minigames'

/** Konfig pre input notes */
export const notesConfig = {

  /** farbicky */
  colors: {
    default: new THREE.Color('#6b6e99'),
    perfect: new THREE.Color('#0de7cc'),
    excellent: new THREE.Color('#faff00'),
    good: new THREE.Color('#f6925f'),
    poor: new THREE.Color('#d93333'),
    white: new THREE.Color('#ffffff')
  },

  /** zaciatok a koniec ciary */
  linePoints: [
    new THREE.Vector3(0, 0, 3.5),
    new THREE.Vector3(0, 0, -3.5),
  ],
  /** offset ciary */
  lineOffset: new THREE.Vector3(-1.5, 0.2, 0),

  /** labels */
  labels: ['perfect', 'excellent', 'good', 'poor'],
  labelStyle: {
    Left: {
      width: '220px',
      height: '120px',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginLeft: -75
    },
    Right: {
      width: '220px',
      height: '120px',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginLeft: 75
    }
  }

}