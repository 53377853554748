import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  corePhasesManager,
  CorePhases,
  timeManager,
  minigameConfig,
  modes
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig,
  velocityConfig,
  lungeConfig,
  startConfig
} from '../../config'
import {
  DisciplinePhases,
  PlayerStates,
  EmotionTypesFinish,
  LungeStates,
  Tasks,
  CustomGameEvents,
  Sides
} from '../../types'
import {
  AthleteAnimationManager,
  AthleteVelocityManager
} from '.'
import { WorldEnvLinesCreator } from '../env/WorldEnvLinesCreator'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import { endManager } from '@/app/EndManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { trainingTasks } from '@/app/modes/training'
import {
  gamePhaseState,
  lapPositionState
} from '@/stores'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** speed lock */
  public speedLockActive = false

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Manager rychlosti */
  public velocityManager = new AthleteVelocityManager(this)

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** Ci uz je v cieli alebo nie */
  public finished = false

  /** Stav hraca */
  private state = PlayerStates.prepare

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** Posledna pozicia atleta */
  public lastAthletePosition = new THREE.Vector3()

  /** dolezity manager na pohyb */
  public worldEnvLinesManager!: WorldEnvLinesCreator

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** Emocia v cieli */
  public emotionFinish = EmotionTypesFinish.looser

  /** Aktualny stav pre lunge */
  public lungeState = LungeStates.none

  /** Aktualny pocet frameov od zaciatku aktualneho stavu pre lunge */
  public lungeActualFrame = 0

  /** Max pocet frameov pre aktualny stav pre lunge */
  public lungeMaxFrames = 0

  /** Kolko frameov treba pockat, aby sa nastavil stav konca */
  private framesToWaitForEndState = 0

  /** Ci sa ma aplikovat spomalovanie aj rychlosti animacie */
  private changeAnimationSpeedFalseStart = false

  /** Rychlost na zaciatku false startu spomalovania */
  private speedOnStartFalseStart = 0

  /** Ci sa maju aktualizovat framey (mali by sa po starte) */
  private updateFramesAfterStart = false

  /** Pocet frameov po starte */
  private framesAfterStart = 0

  /** Pocet frameov, kolko zostane v lunge casti, kde sa nemeni offset */
  private lungeFramesStay = 0

  /** Cas v cieli */
  public finalTime = minigameConfig.dnfValue

  /** ci sa ma spustit lunge same */
  public tutorialAutoLunge = false

  /** Prefix pre pohlavie a 3d objekty */
  protected prefixSex = ''

  /** Objekt pre vlasy */
  protected hair!: THREE.Mesh

  /** Objekt pre cop */
  protected hairTail!: THREE.Mesh

  /** Typ vlasov */
  protected hairType = 1

  /** priebezne poradie */
  public provisionalPosition = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Nastavenie stavu atleta
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setState(state: PlayerStates, maxRandomDelay = 0): void {

    const random = THREE.MathUtils.randFloat(0, maxRandomDelay)

    if (random === 0) {

      this.state = state

    } else {

      gsap.to({}, {
        duration: random,
        callbackScope: this,
        onComplete: () => {

          this.state = state
          console.log('state random', state, this.uuid, random)

        }
      })

    }



  }

  /**
   * Zistenie ci je aktualne dany stav
   * @param state -stav
   * @returns True, ak ano
   */
  public isState(state: PlayerStates): boolean {

    return this.state === state

  }

  /**
   * Zistenie ci je aktualny jeden z danych stavov
   * @param states - mozne stavy
   * @returns True, ak ano
   */
  public isOneOfStates(states: PlayerStates[]): boolean {

    return states.includes(this.state)

  }

  /**
   * Getter pre aktualne percento na drahe
   * @returns - actualPercent
   */
  public getActualPercentOnPath(): number {

    return this.worldEnvLinesManager.getActualPercent()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   * @param athleteName - Meno alteta
   * @param nameForAnimations - Meno pre ziskanie animacii
   */
  public create(trackIndex: number, athleteName: string, nameForAnimations: string): void {

    this.playable = playersManager.getPlayerById(this.uuid)?.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfig,
      game.animations.get(nameForAnimations),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // toto len aby fungovali uvodne nastavovacky, aj tak sa to potom poposuva
    const position = gameConfig.startPosition

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.name = athleteName

    console.log('atlet vytvoreny pre trat: ', trackIndex)

    this.worldEnvLinesManager = new WorldEnvLinesCreator(
      trackIndex,
      () => {

        if (modes.isTutorial()) {

          this.startLunge()

        }

      },
      (actualPos: number, lastPos: number, finishPos: number) => {

        this.finishReached(actualPos, lastPos, finishPos)

      },
      () => {

        this.setState(PlayerStates.endPath)

      },
      this
    )
    this.speedManager.setSpeeds(playersManager.getPlayerById(this.uuid)?.attribute.total || 0, this)
    this.lungeFramesStay = lungeConfig.frames[LungeStates.stay]

  }

  /**
   * Odstartovanie hraca
   * @param side - Strana inputu
   */
  public start(side: Sides): void {

    console.log('odstartoval atlet', this.uuid)
    this.setState(PlayerStates.run)
    this.speedManager.setActive(true)
    // dame rovno prvy input
    const correctSide = this.athleteAnimationManager.animationSide
    let startSpeed = velocityConfig.startSpeed

    // ked dame nespravnu sipku, tak bude postih - neplati pre superov
    if (this.playable) {

      if (side !== correctSide) {

        startSpeed *= velocityConfig.firstIncorrectInputCoef
        this.velocityManager.paddleFrequency = velocityConfig.firstIncorrectInputPaddleFrequency
        console.log('hracovi davame postih za zlu startovaciu stranu')
        disciplinePhasesManager.phaseStart.trainingStartQuality(true)

      } else {

        disciplinePhasesManager.phaseStart.trainingStartQuality(false)

      }

    }
    this.speedManager.setActualSpeed(startSpeed)
    if (this.playable) gamePhaseState().showBar = true
    this.updateFramesAfterStart = true
    this.framesAfterStart = 0

  }

  /**
   * Spomalovanie pri false starte
   */
  public falseStartSlowDown(): void {

    if (this.playable) gamePhaseState().showBar = false
    this.setState(PlayerStates.falseStartSlowDown)
    this.velocityManager.inputsBlocked = true
    // ku rychlosti musime pridat kusok, lebo potom bude max a actual speed rovnake a bude tam delenie 0
    this.speedOnStartFalseStart = this.speedManager.getActualSpeed() + 0.1
    this.speedManager.setSpeedFalseStart()
    this.changeAnimationSpeedFalseStart = true

  }

  /**
   * Aktualizovanie pozicie lyziara
   * @param positionObj - Pozicia
   * @param actualPhase - aktualna faza
   */
  private updatePlayerPosition(positionObj: THREE.Object3D, actualPhase: DisciplinePhases): void {

    this.athleteObject.position.copy(positionObj.position)

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(actualPhase)

    let t = 0.5
    if (instalerp) t = 1

    this.athleteObject.quaternion.slerp(positionObj.quaternion, t)

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return
    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return


    // velocity manager sa riesi iba ked je aktivna rychlost, inak je to neziaduce
    if (this.speedManager.isActive()) this.velocityManager.update()
    this.speedManager.update()

    this.manageLunge()

    // kontrola a spustenie stavu konca
    this.manageEnd()

    this.athleteAnimationManager.update()

    if (this.updateFramesAfterStart) {

      this.framesAfterStart += 1

      // if (this.framesAfterStart % 10 === 0) {

      //   const frame = this.framesAfterStart
      //   const percent = this.worldEnvLinesManager.getActualPercent()
      //   const percentStart = pathsConfig.positionsStart[this.worldEnvLinesManager.pathIndex - 1]
      //   const meters = (percent - percentStart) * this.worldEnvLinesManager.totalPathLength
      //   console.log(`atlet ${this.uuid} presiel vo frame ${frame} tolkoto metrov: ${meters}`)

      // }

    }

    if (!this.playable || this.finished) return
    lapPositionState().position = gameConfig.positionDictionary[this.provisionalPosition]

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   * @param inTop3 - Ci je hrac v top 3
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases, inTop3 = false): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return
    this.checkSpeedLock()

    const point = this.worldEnvLinesManager.update(
      this.speedManager,
      this.isState(PlayerStates.end) || this.isState(PlayerStates.finish),
      inTop3
    )
    this.updatePlayerPosition(point, actualPhase)

    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return

    this.manageFalseStart()

  }

  /**
   * Aktivacia speed lock-u
   */
  public activateSpeedLock(): void {

    console.warn('SPEED LOCK', this.uuid)
    this.speedLockActive = true

    const finishSpeed = this.speedManager.topSpeed -
      (1 - this.velocityManager.lastStrokeQuality) * velocityConfig.maxBoost
    console.log('finish speed', this.uuid, finishSpeed, ',last stroke quality ', this.velocityManager.lastStrokeQuality)
    this.speedManager.setActualSpeed(finishSpeed)

  }

  /**
   * Deaktivovanie spomalenia
   */
  public deactivateSpeedLock(): void {

    console.warn('SPEED LOCK end', this.uuid)
    this.speedLockActive = false

  }

  /**
   * Kontrola, ci nema nastat speed lock
   */
  protected checkSpeedLock(): void {

    if (
      !this.worldEnvLinesManager.isLungeEnabled() ||
      this.speedLockActive ||
      this.finished
    ) return

    if (this.playable) {

      // disciplinePhasesManager.phaseRunning.stopCollectingQualityRun()
      // trainingTasks.saveRunQuality(disciplinePhasesManager.phaseRunning.getQualityRun())

    }
    this.activateSpeedLock()

  }

  /**
   * Sprava veci pre false start
   */
  private manageFalseStart(): void {

    if (this.changeAnimationSpeedFalseStart) {

      const { animationMinSpeed, minSpeed } = startConfig.falseStart
      const actualSpeed = this.speedManager.getActualSpeed()
      const maxSpeed = this.speedOnStartFalseStart
      const percent = (actualSpeed - minSpeed) / (maxSpeed - minSpeed)
      const speed = animationMinSpeed + ((1 - animationMinSpeed) * percent)
      this.animationsManager.setSpeed(speed)

    }

  }

  /**
   * Sprava konca behu v cieli
   */
  private manageEnd(): void {

    if (this.framesToWaitForEndState > 0) {

      this.framesToWaitForEndState -= 1
      if (this.framesToWaitForEndState === 0) {

        this.setEndState()

      }

    }

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    const totalStrength = playersManager.getPlayerById(this.uuid)?.attribute.total || 0

    let attrStrength = totalStrength / 4000 + 0.75
    if (totalStrength >= 1000) {

      attrStrength = (totalStrength - 1000) / 5000 + 1

    }

    return attrStrength

  }

  /**
   * Ziskanie poctu max frameov pre lunge
   * @returns lungeMaxFrames
   */
  public getLungeMaxFrames(): number {

    return this.lungeState === LungeStates.stay ? this.lungeFramesStay : lungeConfig.frames[this.lungeState]

  }

  /**
   * Zapnutie lunge-u
   */
  public startLunge(): void {

    // aby sa nespustalo viackrat a aby to bolo v zone, kedy to je mozne dat
    if (this.lungeState !== LungeStates.none || !this.worldEnvLinesManager.isLungeEnabled()) return

    this.setState(PlayerStates.lunge)
    this.lungeState = LungeStates.forward
    this.lungeActualFrame = 0
    this.tutorialAutoLunge = false

  }

  /**
   * Nastavenie lunge offsetu, v % trate
   */
  public setLungeOffset(): void {

    if (!this.isState(PlayerStates.lunge)) {

      this.worldEnvLinesManager.lungeOffset = 0
      return

    }

    // ak sme v stay stave, tak mame max
    let offsetPercent = 1

    if (this.lungeState === LungeStates.forward) {

      offsetPercent = this.lungeActualFrame / this.getLungeMaxFrames()

    }
    if (this.lungeState === LungeStates.backward) {

      offsetPercent = 1 - (this.lungeActualFrame / this.getLungeMaxFrames())

    }

    this.worldEnvLinesManager.lungeOffset = offsetPercent * (lungeConfig.lungeOffsetMax - lungeConfig.lungeOffsetMin) *
      this.worldEnvLinesManager.oneMeterInPercent

  }

  /**
   * Managovanie lunge-u
   */
  private manageLunge(): void {

    if (!this.isState(PlayerStates.lunge) || this.lungeState === LungeStates.none) return

    this.lungeActualFrame += 1

    if (this.lungeActualFrame > this.getLungeMaxFrames()) {

      // ak uz sme skoncili backward cast, tak davame naspat run state
      if (this.lungeState === LungeStates.backward) {

        // staci dat run, lebo cielovy stav sa dava az potom, co dobehne urcite lunge
        // this.setState(PlayerStates.run)
        return

      }

      if (gameConfig.autoMove.isEnabled && this.playable && this.finalTime === minigameConfig.dnfValue) return

      this.lungeState += 1 // prepneme na dalsi stav
      this.lungeActualFrame = 0

    }

    this.setLungeOffset()

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    this.deactivateSpeedLock()

    this.finished = true
    const finishDist = actualPosition - finishPosition
    const lastFrameDist = actualPosition - lastPosition

    const provisionalTime = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 16)
    const offsetTime = (finishDist / lastFrameDist) / 30
    // this.finalTime = Math.ceil((provisionalTime - offsetTime) * 100) / 100
    this.finalTime = provisionalTime - offsetTime
    this.finalTime = Math.round(((provisionalTime - offsetTime) * 1000) + Number.EPSILON ) / 1000
    endManager.setPotentialWinnerInFinish(this.uuid, this.finalTime)

    this.updateFramesAfterStart = false
    audioHelper.setTimerToChangeAudienceAfterFinish()

    console.log(
      `urcovanie presneho casu: ${this.uuid}`,
      `finishDist = ${finishDist}`,
      `lastFrameDist = ${lastFrameDist}`,
      `provisionalTime = ${provisionalTime}`,
      `offsetTime = ${offsetTime}`,
      `finalTime = ${this.finalTime}`
    )

    // animacie v cieli sa budu riesit az neskor, aby stihol dobehnut lunge
    const { frames } = lungeConfig
    this.framesToWaitForEndState = frames[LungeStates.forward] + frames[LungeStates.stay] +
      frames[LungeStates.backward] + 2

    // mimo dennej ligy davame vsetkym superom, co este nepresli cielom po hracovi DNF, aby sa dobre vyhodnocovalo
    if (!modes.isDailyLeague() && this.playable) window.dispatchEvent(new CustomEvent(CustomGameEvents.playerFinished))

    if (!modes.isTrainingMode()) return

    let lungeEfficiency = 100 / (lungeConfig.lungeOffsetMax - lungeConfig.lungeOffsetMin) *
      (this.worldEnvLinesManager.lungeOffset / this.worldEnvLinesManager.oneMeterInPercent)
    lungeEfficiency = Math.round(lungeEfficiency) / 100

    trainingTasks.saveTaskValue(Tasks.lunge, lungeEfficiency)

  }

  /**
   * Nastavenie stavu konca
   */
  private setEndState(): void {

    this.setState(PlayerStates.end)

    const resultEmotionWinner = endManager.isWinnerInFinish(this.uuid)
    if (resultEmotionWinner) {

      // davame animaciu happy
      this.emotionFinish = EmotionTypesFinish.winner

    }

    const { minSpeed, framesToMinSpeed, randomizeEnd } = velocityConfig.speedOnEnd

    // zacneme spomalovat
    this.speedManager.setDataForSpeedOnEnd(
      this.speedManager.getActualSpeed(),
      minSpeed,
      THREE.MathUtils.randInt(framesToMinSpeed - randomizeEnd.minus, framesToMinSpeed + randomizeEnd.plus),
    )

  }

  /**
   * reset atleta
   */
  public reset(): void {

    this.state = PlayerStates.prepare
    this.activeUpdatingMovementAnimations = false
    this.lastAthletePosition.set(0, 0, 0)
    this.emotionFinish = EmotionTypesFinish.looser
    this.lungeState = LungeStates.none
    this.lungeActualFrame = 0
    this.lungeMaxFrames = 0
    this.framesToWaitForEndState = 0
    this.changeAnimationSpeedFalseStart = false
    this.speedOnStartFalseStart = 0
    this.updateFramesAfterStart = false
    this.framesAfterStart = 0
    this.finalTime = minigameConfig.dnfValue
    this.provisionalPosition = 0

    this.velocityManager.reset()
    this.speedManager.reset()
    this.animationsManager.resetAll()
    this.worldEnvLinesManager.reset()
    this.athleteAnimationManager.reset()
    this.finished = false

  }

}
