import { defineStore } from 'pinia'
import type { BoatData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/BoatStats.vue'

export interface BoatStatsState {
  boatsData: BoatData[],
  showStats: boolean,
  text: string
}

const initialState = (): BoatStatsState => ({
  boatsData: [],
  showStats: false,
  text: ''
})

export const boatStatsState = defineStore('boatStatsState', {
  state: initialState,
})
