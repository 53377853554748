import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  gsap,
  TutorialMessageColors,
  MobileDetector,
  requestManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { player } from '@/app/entities/athlete/player'
import { endManager } from '@/app/EndManager'
import {
  blurState,
  tutorialState
} from '@/stores'
import { tutorialCoreState } from '@powerplay/core-minigames-ui-ssm'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** aktualny event */
  private activeEventType = TutorialEventType.awaitingEvent

  /** ci uz skoncil prvu zakrutu */
  private firstFailedStartDone = false

  /** kolko nedal za sebou zaberov */
  private consequentMissedPaddles = 0

  /** kolko dal za sebou zlych zaberov */
  private consequentBadPaddles = 0

  /** ci uz je missed message */
  private missedActive = false

  /** ci uz je bad message */
  private tooBadActive = false

  /** pocet dobrych zaberov */
  public numberOfGoodPaddles = 0

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-1`
      },
      {
        id: TutorialObjectiveIds.goodPaddles as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-2`
      },
      {
        id: TutorialObjectiveIds.lunge as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-3`
      },
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    this.setObjectivesInit()
    opponentsManager.isActive = false
    player.tutorialAutoLunge = true
    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false
          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().buttons.showMovementButtons = false

        },
        sectionLogicFinish: () => {

          tutorialState().buttons.showMovementButtons = false
          this.resetTypeWrite()
          disciplinePhasesManager.phaseStart.launchSystem()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionFourth,
        id: 4,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionFifth,
        id: 5,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionSixth,
        id: 6,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionSeventh,
        id: 7,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEight,
        id: 8,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return
    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.autoLunge) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-7`)
      tutorialUIChange.setAnne(true)


    }
    if (eventType === TutorialEventType.failedStart) {

      tutorialObjectives.falseStartCount += 1
      if (this.firstFailedStartDone) {

        disciplinePhasesManager.phaseStart.finishFalseStart()
        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.firstFailedStartDone = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-3`)
      tutorialUIChange.setAnne(true)
      disciplinePhasesManager.phaseStart.showShine = false

    }
    if (eventType === TutorialEventType.goodStart) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-4`, undefined, undefined, true)
      tutorialUIChange.setAnne(true, true)

      tutorialState().buttons.showMovementButtons = true
      disciplinePhasesManager.phaseStart.showShine = false

    }
    if (eventType === TutorialEventType.firstFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-5`)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.goodFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-8`, TutorialMessageColors.green)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.badFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-9`, TutorialMessageColors.red)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.missed) {

      tutorialState().$patch({
        instructionBoxText: 'tutorialText15-5',
        instructionBoxIcon: -1,
        showInstructionBox: true
      })

    }
    if (eventType === TutorialEventType.missedFixed) {

      tutorialState().showInstructionBox = false

    }
    if (eventType === TutorialEventType.tooBad) {

      tutorialState().$patch({
        instructionBoxText: 'tutorialText15-6',
        instructionBoxIcon: -1,
        showInstructionBox: true
      })

    }
    if (eventType === TutorialEventType.tooBadFixed) {

      tutorialState().showInstructionBox = false

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.failedStart) {

      disciplinePhasesManager.phaseStart.finishFalseStart()

    }
    if (this.activeEventType === TutorialEventType.goodStart) {


      tutorialManager.blockInputsManually()
      tutorialManager.nextSection()

    }
    if (this.activeEventType === TutorialEventType.firstFinish) {

      opponentsManager.isActive = true
      endManager.reset()
      disciplinePhasesManager.resetAttempt()

    }
    if (
      [
        TutorialEventType.goodFinish,
        TutorialEventType.badFinish
      ].includes(this.activeEventType)
    ) {

      tutorialManager.blockInputsManually()
      tutorialManager.nextSection(),
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

    }

    tutorialState().buttons.showMovementButtons = false
    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * Riesenie podmienok pri padlovani
   * @param inputPressed - ci zapadloval
   * @param goodInput - ci spravne padlo
   */
  public managePaddle(inputPressed: boolean, goodInput: boolean): void {

    if (!inputPressed) {

      if (!this.tooBadActive) {

        this.consequentBadPaddles = 0

      }
      if (this.missedActive) return
      if (this.consequentMissedPaddles < 3) {

        this.consequentMissedPaddles += 1

      }
      if (this.consequentMissedPaddles === 3) {

        this.missedActive = true
        this.eventActionTrigger(TutorialEventType.missed)

      }



    } else {

      if (goodInput) {

        if (this.consequentBadPaddles > 0) {

          this.consequentBadPaddles -= 1

        }
        if (this.consequentBadPaddles === 1) {

          this.tooBadActive = false
          this.eventActionTrigger(TutorialEventType.tooBadFixed)

        }

      } else if (!this.tooBadActive) {

        if (this.consequentBadPaddles < 3) {

          this.consequentBadPaddles += 1

        }
        if (this.consequentBadPaddles === 3) {

          this.tooBadActive = true
          this.eventActionTrigger(TutorialEventType.tooBad)

        }

      }

      if (this.consequentMissedPaddles > 0 && !this.missedActive) this.consequentMissedPaddles = 0
      if (this.consequentMissedPaddles > 0) {

        this.consequentMissedPaddles -= 1

      }
      if (this.consequentMissedPaddles === 1) {

        this.missedActive = false
        this.tooBadActive = false
        this.eventActionTrigger(TutorialEventType.missedFixed)

      }

    }

  }

  /**
   * Spracovanie dobreho padlovania
   */
  public manageGoodPaddle(): void {

    this.numberOfGoodPaddles += 1
    if (this.numberOfGoodPaddles <= 10) {

      tutorialState().goodPaddles = this.numberOfGoodPaddles

    }
    if (this.numberOfGoodPaddles === 10) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.goodPaddles as string)

    }

  }

  /**
   * Po prejdeni ciela
   */
  public finishPassed(): void {

    tutorialObjectives.passObjective(TutorialObjectiveIds.lunge as string)
    gsap.to({}, {
      duration: 2,
      onComplete: () => {

        tutorialObjectives.time = player.finalTime
        if (tutorialObjectives.isAllObjectivesPassed()) {

          this.eventActionTrigger(TutorialEventType.goodFinish)
          return

        }
        this.eventActionTrigger(TutorialEventType.badFinish)
        return

      }
    })

  }

  /**
   * reset
   */
  public reset(): void {

    this.consequentMissedPaddles = 0
    this.consequentBadPaddles = 0
    this.numberOfGoodPaddles = 0

  }

}

export const tutorialFlow = new TutorialFlow()
