import { defineStore } from 'pinia'

export interface BlackOverlayState {
  showBlackScreen: boolean
}

const initialState = (): BlackOverlayState => ({
  showBlackScreen: false
})

export const blackOverlayState = defineStore('blackOverlayState', {
  state: initialState,
})
