import {
  gameConfig,
  opponentConfig,
  tutorialConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  MaterialsNames,
  ModelsNames,
  Sides
} from '@/app/types'
import {
  game,
  gsap,
  THREE,
  playersManager,
  type PlayerInfo,
  modes,
  PlayerSex,
  HairColorTypes,
  gauss
} from '@powerplay/core-minigames'
import { player } from '../player'
import { materialsConfig } from '@/app/config/materialsConfig'
import { Athlete } from '..'

/** Trieda pre spravu protihraca */
export class Opponent extends Athlete {

  /** pocet framov do startu */
  private delayedStartFrames = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   * @param materialIndex - index materialu
   */
  public constructor(public uuid: string, private materialIndex: number) {

    super(uuid)
    if (modes.isEventBossFight()) return


  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone
    if (playersManager.getPlayer().sex !== sex) objectForClone = game.getObject3D('athlete_opponent')

    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    materialsConfig[ModelsNames.athlete]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.athleteOpponentPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })

    materialsConfig[MaterialsNames.boat]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.boatPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })

    materialsConfig[MaterialsNames.athleteHair]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(`${MaterialsNames.athleteHairOpponentPrefix + this.materialIndex}`) as THREE.MeshBasicMaterial

    })

    return athleteObject

  }

  /**
   * Vytvorenie protihraca
   * @param trackIndex - Index drahy
   */
  public create(trackIndex: number): void {

    console.log('vytvaram protihraca...', this.uuid)

    const info = playersManager.getPlayerById(this.uuid) as PlayerInfo
    const sufix = playersManager.getPlayer().sex !== info.sex ?
      '_opponent' :
      ''

    super.create(trackIndex, `Opponent${ this.uuid}`, ModelsNames.athlete + sufix)

    this.animationsManager.pauseAll()
    gsap.to({}, {
      onComplete: () => {

        this.animationsManager.unpauseAll()

      },
      duration: 1
    })

    // vygenerujeme pole hodnot pre speedbar
    this.generateSpeedbarValues()

    // vygenerujeme percento na trati, kde bude lunge
    this.worldEnvLinesManager.generateLungePosition()

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    this.prefixSex = sex === PlayerSex.female ? 'f_' : ''
    // spravime zmeny s vlasmi a klobukmi + ruky schovame, ak nahodou boli
    this.setHair()

  }

  /**
   * Nastavenie vlasov a klobuku
   */
  private setHair(): void {

    const opponentSufix = `opponent_${this.materialIndex}`
    console.log('....', this.athleteObject)
    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low_${opponentSufix}`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low_${opponentSufix}`) as THREE.Mesh
    console.log(
      'HAIRRRRRS', this.hair, this.hairTail, this.prefixSex, `${this.prefixSex}hair_low_${opponentSufix}`,
      `${this.prefixSex}tail_low`
    )

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const maxCountHairTypes = 3
    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.hair.visible = hairTypesVisible.includes(this.hairType)
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType)

  }

  /**
   * Vygenerovanie hodnot pre speedbar
   */
  private generateSpeedbarValues(): void {

    const { valuesToGenerate, gauss1, gauss2, gauss3, gauss4 } = opponentConfig.speedbar

    let center = gauss1.center
    let dev = gauss1.dev
    let min = gauss1.min
    let max = gauss1.max
    const attributeDiff = (playersManager.getPlayerById(this.uuid)?.attribute.total ?? 0) -
      playersManager.getPlayer().attribute.total

    if (attributeDiff > gauss2.minAttributeDiff) {

      center = gauss2.center
      dev = gauss2.dev
      min = gauss2.min
      max = gauss2.max

    }
    if (attributeDiff > gauss3.minAttributeDiff) {

      center = gauss3.center
      dev = gauss3.dev
      min = gauss3.min
      max = gauss3.max

    }
    if (attributeDiff > gauss4.minAttributeDiff) {

      center = gauss4.center
      dev = gauss4.dev
      min = gauss4.min
      max = gauss4.max

    }

    console.log('Generate speed bar values for opp ', this.uuid, ' gauss: ', center, dev, min, max)

    // vygenerujeme x hodnot speedbaru
    for (let i = 0; i < valuesToGenerate; i += 1) {

      let value = 0

      // kazda x-ta hodnota bude
      value = 1 - gauss.getValue(center, dev, min, max) / 25

      this.velocityManager.speedbarValues.push(value)

    }

    console.log(`generated speedbar values opponent ${this.uuid}:`, this.velocityManager.speedbarValues)

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param frame - aktualny frame
   * @param inTop3 - Ci je super v top3
   */
  public updateBeforePhysics(frame = 0, inTop3: boolean): void {

    super.updateBeforePhysics(disciplinePhasesManager.actualPhase, inTop3)

    // skontrolujeme, ci netreba odstartovat
    this.checkDelayedStart(frame)

  }

  /**
   * Vymazanie vsetkych callbackov z animations managera
   */
  public removeCallbacksFromAllAnimations(): void {

    this.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastavenie reakcnych casov
   */
  public setDelayedStartFrames(): void {

    const { from, to } = opponentConfig.startFrames
    const fromByStrength = from
    const toByStrength = to

    // const strength = playersManager.getPlayerById(this.uuid)?.attribute.total || 0
    // if (strength <= 100) {

    //   fromByStrength = 6
    //   toByStrength = 9

    // } else if (strength > 100 && strength <= 200) {

    //   fromByStrength = 5
    //   toByStrength = 8

    // }
    this.delayedStartFrames = Math.round(Math.random() * (toByStrength - fromByStrength) + fromByStrength)

    if (modes.isTutorial()) this.delayedStartFrames = tutorialConfig.opponent.reactionTime

    console.log(`Protihracov ${this.uuid} reakcny cas (frame) = ${this.delayedStartFrames}`)
    // this.actualState = OpponentState.start

  }

  /**
   * Skontrolovanie oneskoreneho startu podla reakcneho casu
   * @param frame - aktualny frame
   */
  private checkDelayedStart(frame: number): void {

    if (this.delayedStartFrames !== frame) return

    disciplinePhasesManager.phaseStart.manageFirstStarted()
    this.start(Sides.LEFT) // tu je jedno aka pojde strana

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)
    if (!disciplinePhasesManager.phaseFinish.dailyLeagueSetResultsOpponentsFreeze) {

      playersManager.setPlayerResultsById(this.uuid, this.finalTime)

    }

    disciplinePhasesManager.phaseFinish.finishedOpponents += 1

  }

  /**
   * reset atleta
   */
  public reset(): void {

    super.reset()
    this.generateSpeedbarValues()

  }

}
