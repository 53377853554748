import {
  gameConfig,
  pathsConfig,
  lungeConfig,
  opponentConfig,
  cameraConfig,
  velocityConfig
} from '@/app/config'
import type { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  CameraStates,
  THREE,
  audioManager,
  cameraManager,
  playersManager
} from '@powerplay/core-minigames'
import { linesManager } from './LinesManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { Athlete } from '../athlete'
import { AudioNames } from '@/app/types'
import { textMessageState } from '@/stores'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class WorldEnvLinesCreator {

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** Percento kde je aktualne hrac */
  private actualPercent = 0

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Percento kde bol v cieli hrac */
  private finishPercent = 0

  /** Kolko percent je 1 meter na krivke */
  public oneMeterInPercent = 0

  /** Posledny offset od idealu v zakrute */
  public lastOffsetFromIdealInTurn = 0

  /** trat hraca */
  public playerPath?: THREE.CurvePath<THREE.Vector3>

  /** aktivna cesta hraca */
  public finishPhaseStarted = false

  /** Ci uz bola prehrata animacia lunge alebo nie */
  private lungeStarted = false

  /** Kolko m ma trat */
  public totalPathLength = 0

  /** V kolkatich % trate bude vykonany lunge */
  private lungePositionPercent = 0

  /** Index drahy, v ktorej zacina hrac */
  public pathIndex = 0

  /** Aktualny offset pre lunge */
  public lungeOffset = 0

  /** Aktualny idealny pomocny offset */
  public actualIdealOffset?: THREE.Vector3

  /** Ci uz hrac presiel 100m do ciela */
  private done100MetersToGo = false

  /** Ci uz hrac presiel 50m do ciela */
  private done50MetersToGo = false

  /** pocet metrov pred icelom, kedy je dostupny lunge */
  private distanceBeforeFinish = 0

  /**
   * Konstruktor
   * @param pathIndex - index pre trat
   * @param animationsManager - manager pre animaciu
   */
  public constructor(
    pathIndex: number,
    private callbackLunge: () => unknown,
    private callbackFinish: (actualPos: number, lastPos: number, finishPos: number) => unknown,
    private callbackEndOfPath: () => unknown,
    private athlete: Athlete
  ) {

    this.playerPath = linesManager.getPath(pathIndex)
    this.pathIndex = pathIndex

    this.setActualPercentOnStartIntro()
    this.callbackLunge = callbackLunge
    this.callbackFinish = callbackFinish
    this.calculateLineInfo()
    this.setupObject3d()
    this.setDistanceBeforeFinish()

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie poslednych percent na trati
   * @returns hodnota % na trati
   */
  public getLastPercent(): number {

    return this.lastPercent

  }

  /**
   * Vratenie cielovych percent na trati
   * @returns hodnota % na trati
   */
  public getFinishPercent(): number {

    return this.finishPercent

  }

  /**
   * Nastavenie distanceBeforeFinish
   */
  private setDistanceBeforeFinish(): void {

    // davame podla atributu hraca
    const attribute = playersManager.getPlayer().attribute.total
    const { max, min } = lungeConfig.distanceBeforeFinish

    this.distanceBeforeFinish = this.distanceBeforeFinish = min + (max - min) * ((attribute - 100) / 900)

    if (this.distanceBeforeFinish > max) this.distanceBeforeFinish = max
    if (this.distanceBeforeFinish < min) this.distanceBeforeFinish = min

    console.log('distanceBeforeFinish: ', this.distanceBeforeFinish)

  }

  /**
   * Nastavenie pociatocnej pozicie pre intro
   */
  private setActualPercentOnStartIntro(): void {

    this.actualPercent = pathsConfig.positionsStartIntro[this.pathIndex - 1]

  }

  /**
   * Nastavenie pociatocnej pozicie pre start
   */
  public setActualPercentOnStart(): void {

    this.actualPercent = pathsConfig.positionsStart[this.pathIndex - 1]

    // ked potrebujeme skip do ciela
    if (gameConfig.skipToFinish.active) this.actualPercent = gameConfig.skipToFinish.percent

    this.setupObject3d()

  }

  /**
   * Vygenerovanie lunge pozicie
   */
  public generateLungePosition(fixMeters: number | undefined = undefined): void {

    const finishPercent = pathsConfig.positionsFinish[this.pathIndex - 1]

    if (fixMeters !== undefined) {

      this.lungePositionPercent = finishPercent - (fixMeters * this.oneMeterInPercent)
      return

    }

    const { min, max } = opponentConfig.lunge.metersBeforeFinish
    this.lungePositionPercent = THREE.MathUtils.randFloat(
      finishPercent - (max * this.oneMeterInPercent),
      finishPercent - (min * this.oneMeterInPercent)
    )
    console.log('Lunge position: ', this.lungePositionPercent)

  }

  /**
   * Skontrolovanie, ci sa ma dat lunge alebo nie
   * @param inTop3 - Ci je hrac v top3
   */
  private checkLunge(inTop3: boolean): void {

    if (
      this.athlete.playable &&
      this.athlete.tutorialAutoLunge &&
      this.actualPercent >= this.lungePositionPercent &&
      !this.lungeStarted
    ) {

      this.callbackLunge()

    }

    // hrac bude davat sam lunge cez inputy a lunge sa dava iba raz a az za definovanou poziciou
    if (this.athlete.playable || this.lungeStarted || this.actualPercent < this.lungePositionPercent) return

    this.lungeStarted = true

    // lunge budu davat superi iba ked budu v top3 aktualne
    if (inTop3) this.athlete.startLunge()

  }

  /**
   * Skontrolovanie, ci sa ma dat finish alebo nie
   */
  private checkFinish(): void {

    if (!this.isFinishEnabled() || this.finishPhaseStarted) return

    this.callbackFinish(
      this.actualPercent * this.totalPathLength,
      this.lastPercent * this.totalPathLength,
      (pathsConfig.positionsFinish[this.pathIndex - 1] -
        (this.lungeOffset + (lungeConfig.lungeOffsetMin * this.oneMeterInPercent))) *
        this.totalPathLength
    )

    this.finishPercent = this.actualPercent
    this.finishPhaseStarted = true

  }

  /**
   * Kontrola stredu drahy a pustenie komentatora v nej
   */
  private checkHalfway(): void {

    if (!this.athlete.playable || this.done100MetersToGo) return

    const meters = (this.actualPercent - pathsConfig.positionsStart[this.pathIndex - 1]) / this.oneMeterInPercent
    if (meters >= 100) {

      this.done100MetersToGo = true

      const position = this.athlete.provisionalPosition + 1
      let audioName = AudioNames.commentatorHalfwayRank4
      if (position === 1) {

        audioName = AudioNames.commentatorHalfWayRank1

      } else if (position <= 3 ) {

        audioName = AudioNames.commentatorHalfwayRank23

      }

      audioManager.play(audioName)

      textMessageState().$patch({
        showTogo: true,
        showType: 0
      })

    }

  }

  /**
   * Kontrola stredu drahy a pustenie komentatora v nej
   */
  private check50MetersToGo(): void {

    if (!this.athlete.playable || this.done50MetersToGo) return

    const meters = (this.actualPercent - pathsConfig.positionsStart[this.pathIndex - 1]) / this.oneMeterInPercent
    if (meters >= 150) {

      this.done50MetersToGo = true

      textMessageState().$patch({
        showTogo: true,
        showType: 1
      })

    }

  }

  /**
   * Skontrolovanie, ci uz nie je koniec trate
   */
  private checkEndOfPath(): void {

    if (this.actualPercent >= 1) this.callbackEndOfPath()

  }

  /**
   * Update funkcia
   * @param speedManaager - Speed manager
   * @param isEnd - Ci ide o veci v cieli
   * @param inTop3 - Ci je hrac v top 3
   * @returns - Novy object 3D
   */
  public update(speedManager: SpeedManager, isEnd: boolean, inTop3: boolean): THREE.Object3D {

    if (speedManager.isActive()) {

      if (this.athlete.velocityManager.paused) return this.object3d
      // rychlost v m/frame
      const actualSpeed = isEnd ?
        speedManager.getActualSpeedPerFrameOnEnd() :
        speedManager.getActualSpeedPerFrame()

      // rychlost v %/frame
      const speedLockDivider = (disciplinePhasesManager.phaseRunning.speedLockActive && !isEnd) ?
        disciplinePhasesManager.phaseRunning.speedLockCoef :
        1
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed * speedLockDivider

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed

      this.dollyZoom()

      // kontrola veci - triggerov
      this.checkLunge(inTop3)
      this.checkFinish()
      this.checkEndOfPath()
      this.checkHalfway()
      this.check50MetersToGo()

      this.setupObject3d()

    } else if (
      (
        cameraManager.isThisCameraState(CameraStates.disciplineIntro) ||
        cameraManager.isThisCameraState(CameraStates.disciplineIntroSecond)
      ) &&
      this.actualPercent < pathsConfig.positionsStart[this.pathIndex]
    ) {

      this.actualPercent += 0.5 / 30 * this.oneMeterInPercent
      this.setupObject3d()

    }

    return this.object3d

  }

  /**
   * Nastavenie dolly zoomu podla pozicie hraca na trati
   */
  private dollyZoom(): void {

    if (
      !this.athlete.playable ||
      this.athlete.speedLockActive ||
      disciplinePhasesManager.phaseRunning.cameraFromSide
    ) {

      return

    }

    const intervalFrame = this.athlete.velocityManager.intervalFrames

    const percent = 1 - Math.abs((intervalFrame / this.athlete.velocityManager.paddleFrequency / 2 - 1))
    const { startSpeed } = velocityConfig
    const addCameraoffset = (this.athlete.speedManager.getActualSpeed() - startSpeed) /
      (this.athlete.speedManager.topSpeed - startSpeed)

    const offsetPoint = WorldEnvLinesCreator.getPointInBetweenByPerc(
      new THREE.Vector3(
        gameConfig.cameraConfig.idealOffset.x,
        gameConfig.cameraConfig.idealOffset.y,
        gameConfig.cameraConfig.idealOffset.z - addCameraoffset
      ),
      new THREE.Vector3(
        cameraConfig.dollyZoom.idealOffset.x,
        cameraConfig.dollyZoom.idealOffset.y,
        cameraConfig.dollyZoom.idealOffset.z - addCameraoffset
      ),
      percent
    )

    if (this.actualIdealOffset === undefined) {

      this.actualIdealOffset = offsetPoint

    }
    this.actualIdealOffset.lerp(
      offsetPoint,
      cameraConfig.dollyZoom.alpha
    )
    cameraManager.changeIdeals(this.actualIdealOffset)

  }

  /**
   * Vypocitanie bodu medzi dvoma vektormi podla percenta
   */
  public static getPointInBetweenByPerc(pointA: THREE.Vector3, pointB: THREE.Vector3, percentage: number) {

    let dir = pointB.clone().sub(pointA)
    const len = dir.length()
    dir = dir.normalize().multiplyScalar(len * percentage)
    return pointA.clone().add(dir)

  }

  /**
   * Ci uz je hrac v cieli
   * @returns boolean
   */
  public isFinishEnabled(): boolean {

    return this.actualPercent + this.lungeOffset + (lungeConfig.lungeOffsetMin * this.oneMeterInPercent) >
     pathsConfig.positionsFinish[this.pathIndex - 1]

  }

  /**
   * Ci je moznost aktivovat lunge
   * @returns True, ak ano
   */
  public isLungeEnabled(): boolean {

    const percent = this.oneMeterInPercent * this.distanceBeforeFinish
    const positionLunge = pathsConfig.positionsFinish[this.pathIndex - 1] - percent
    return this.actualPercent > positionLunge && !this.isFinishEnabled()

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo(): void {

    this.totalPathLength = this.playerPath?.getLength() || 0
    console.warn('length', this.totalPathLength)

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / this.totalPathLength
    this.lungeOffset = 0

  }

  /**
   * nastavenie object3d na zaciatku
   */
  private setupObject3d(): void {

    if (this.playerPath === undefined) return

    const point = this.getNewPoint(this.lungeOffset)

    const pointToLookAt = this.getNewPoint(this.lungeOffset +
      0.0016)
    if (point) {

      this.object3d.position.set(point.x, point.y + gameConfig.yPlayerCorrection, point.z)
      if (pointToLookAt) pointToLookAt.y += gameConfig.yPlayerCorrection

    }

    if (pointToLookAt) {

      this.object3d.lookAt(pointToLookAt)

    }

  }

  /**
   * Vypocitanie noveho bodu pre hraca
   * @returns novy bod alebo undefined ak sa nieco pokazilo
   */
  private getNewPoint(offset = 0): THREE.Vector3 | undefined {

    const point = this.playerPath?.getPointAt(this.actualPercent + offset)
    if (point === undefined) return

    return point

  }

  /** reset */
  public reset(): void {

    this.setActualPercentOnStartIntro()
    this.lungeStarted = false
    this.finishPhaseStarted = false
    this.calculateLineInfo()
    this.setupObject3d()
    this.actualIdealOffset = undefined
    this.done100MetersToGo = false
    this.done50MetersToGo = false

  }

}
