<template>
  <div
    class="traffic-component"
    :style="{transform: `scale(${scaleCoef})`}"
  >
    <start-up-text
      v-if="startMessageState.showMessage2"
      :type="startMessageState.messageType2"
      class="start-up-text"
      :is-scaled="false"
      discipline="kayak"
    />
    <traffic-light-box
      v-if="startMessageState.showMessage1"
      is-v2
      class="traffic-light-box"
      :color="startMessageState.messageColor1"
      :text="$t(startMessageState.messageText1)"
      :is-scaled="false"
    />
  </div>
</template>

<script lang="ts">
import {
  TrafficLightBox,
  StartUpText,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { startMessageState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TrafficLightBox,
    StartUpText
  },
  mixins: [WindowAspect],
  data() {

    return {
      startMessageState: startMessageState(),
    }

  }
})

</script>

<style lang="less" scoped>
.traffic-component {
  position: absolute;
  top: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  transform-origin: top;
}
</style>
