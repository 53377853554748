
<template>
  <div
    class="white-shine"
    :style="{opacity: startPhaseState.showShine ? 1 : 0}"
    :class="isMobile() ? 'mobile-overlay' : ''"
  />
</template>

<script lang="ts">

import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'
import { startPhaseState } from '@/stores'

export default defineComponent({
  name: 'WhiteShineComponent',
  mixins: [WindowAspect],
  data() {

    return {
      startPhaseState: startPhaseState(),
    }

  }
})
</script>

<style lang="less">
.white-shine {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow:inset 0px 0px 4vw 4vw rgba(255, 255, 255, 0.62);
    -webkit-box-shadow:inset 0px 0px 4vw 4vw rgba(255, 255, 255, 0.62);

    &.mobile-overlay {
        position: fixed;
    }
}
</style>
