import { flagsConfig } from '@/app/config/flagsConfig'
import {
  fpsManager,
  game,
  modes,
  THREE,
  waterFragmentShader,
  waterVertexShader,
  wakeFragmentShader,
  wakeVertexShader,
  splashesVertexShader,
  splashesFragmentShader
} from '@powerplay/core-minigames'
import { player } from '../athlete/player'
import { opponentsManager } from '../athlete/opponent/OpponentsManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  LeaderColors,
  PlayerStates,
  TexturesNames
} from '@/app/types'
import { endManager } from '@/app/EndManager'
import {
  gameConfig,
  lungeConfig,
  pathsConfig,
} from '@/app/config'
import { gameSettingsState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** Mesh pre ciaru pre lidra */
  private lineLeader!: THREE.Mesh

  /** Mesh pre oblaky */
  private skyboxCloudsMesh!: THREE.Mesh

  /** Ci je povolene hybanie skyboxom */
  public movingSkyboxEnabled = true

  /** options pre meshe vlajok */
  private flagMeshesOptions: {mesh: THREE.Mesh, indexFrom: number, indexTo: number, actualRotation: number}[] = []

  /** progress vlnenia vlajok */
  private progress = 0

  /** Lightmapa vlajok pre hybanie */
  private flagsLightMap!: THREE.Texture | null

  /** Mesh pre terc ako shadow plane */
  public targetBgMesh!: THREE.Mesh

  /** Ci je povolene hybanie vlajkami */
  public movingFlagsEnabled = true

  /** Ci je aktivne zaklopenie zarazok na starte */
  public startlineBlockersActive = false

  /** Stena v cieli */
  private finishWall!: THREE.Mesh

  /** Zarazky na starte */
  private startlineBlockers!: THREE.Object3D

  /** Originalna rotacia pre startovacie zarazky na x osi */
  private startLineBlockersOrigRotationX = 0

  /** Msh vzducholode */
  private blimpMesh!: THREE.Mesh

  /** Smerovy vector pre vzducholod */
  private blimpDir = new THREE.Vector3()

  /** Mesh pre bojky */
  private buoysMesh!: THREE.Mesh

  /** Aktualny smer pohybu bojok */
  private buoysMovementDir = 1

  /** Aktualny offset pre bojky */
  private buoysActualOffset = 0

  /** Mesh pre bojky na starte */
  private buoysMeshStart!: THREE.Mesh

  /** Aktualne maximum pre pohyb bojok */
  private buoysActualMax = 0.03

  /** material pre vodu */
  private waterMaterial!: THREE.ShaderMaterial

  /** alternativny material pre vodu */
  private waterMaterialAlternative!: THREE.MeshBasicMaterial

  /** material pre vlnky pre vodu */
  private waterWakeMaterial!: THREE.ShaderMaterial

  /** materialy pre splechot vody */
  private waterSplashesMaterials: THREE.ShaderMaterial[] = []

  /** Meshe pre splechot vody */
  private splashesPlanes: THREE.Mesh[] = []

  /** Aktualny index pre splechot */
  private splashesActualIndex = 0

  /** hodiny */
  private clock!: THREE.Clock

  /** Timestampy pre splechot vody */
  private splashesTimestamps: number[] = []

  /** Plane pre vlny */
  private wakePlane!: THREE.Mesh

  /** Plane pre vodu */
  private waterPlane!: THREE.Mesh

  /** Helper pre lerp rychlosti */
  private speedLerpHelper = new THREE.Vector2()

  /** Lerp pre rychlost pre shader */
  private speedLerp = new THREE.Vector2()

  /**
   * Vytvorenie prostredia
   */
  public create(clock: THREE.Clock): void {

    this.clock = clock

    console.log('VYTVARAM ENV....')
    // this.createLineLeader()
    this.createWater()
    this.createWaterWake()
    // this.createWaterSplashes()
    this.createSkybox()
    this.createFlags()

    // leader ciaru zatial nepotrebujeme
    const line = game.getMesh('env_LeadIndicator_Line')
    line.visible = false

    // cielova grafika
    this.finishWall = game.getMesh('env_FinishLine_K1')
    this.finishWall.position.y = 2.735
    this.finishWall.updateMatrix()
    this.finishWall.renderOrder = 5
    this.setVisibilityFinishWall(false)

    // pre reklamy staci dat front side
    const adsMaterial = game.getMesh('Env_K1_Mesh003_5').material as THREE.MeshBasicMaterial
    adsMaterial.side = THREE.FrontSide

    this.startlineBlockers = game.getObject3D('StartLine_Blockers')
    this.startlineBlockers.matrixAutoUpdate = true
    this.startLineBlockersOrigRotationX = this.startlineBlockers.rotation.x

    this.blimpMesh = game.getMesh('env_Blimp')
    this.blimpMesh.matrixAutoUpdate = true
    this.blimpDir = this.blimpMesh.getWorldDirection(new THREE.Vector3()).multiplyScalar(0.3)

    this.buoysMesh = game.getMesh('env_Buoys_K1')
    this.buoysMesh.matrixAutoUpdate = true

    this.buoysMeshStart = game.getMesh('StartLine_Mesh004_2')
    this.buoysMeshStart.matrixAutoUpdate = true

    // schovame co netreba
    game.getMesh('Env_K1_Mesh003').visible = false
    game.getObject3D('K1_Track_9').visible = false

    console.log('ENV vytvoreny....')

  }

  /**
   * Vytvorenie skyboxu
   */
  private createSkybox(): void {

    this.skyboxCloudsMesh = game.getMesh('SkyboxDay_Skybox_Clouds')
    this.skyboxCloudsMesh.matrixAutoUpdate = true
    const cloudsMaterial = this.skyboxCloudsMesh.material as THREE.MeshBasicMaterial
    const cloudsTexture = cloudsMaterial.map
    if (cloudsTexture) {

      cloudsTexture.wrapS = THREE.ClampToEdgeWrapping
      cloudsTexture.wrapT = THREE.ClampToEdgeWrapping

    }
    const skyboxFG = game.getMesh('SkyboxDay_Skybox_FG')
    const skyboxBG = game.getMesh('SkyboxDay_Skybox_BG')
    const skyboxBuildings1 = game.getMesh('Buildings_Mesh_1')
    const skyboxBuildings2 = game.getMesh('Buildings_Mesh')
    skyboxFG.renderOrder = 1

    const skyboxScale = 10
    const skyboxBuildingsScale = 4
    skyboxFG.scale.set(skyboxScale, skyboxScale, skyboxScale)
    skyboxFG.rotation.y = Math.PI
    skyboxFG.updateMatrix()
    skyboxBG.scale.set(skyboxScale + 1, skyboxScale + 1, skyboxScale + 1)
    skyboxBG.rotation.y = Math.PI
    skyboxBG.updateMatrix()
    this.skyboxCloudsMesh.scale.set(skyboxScale + 0.5, skyboxScale + 0.5, skyboxScale + 0.5)
    skyboxBuildings1.scale.set(skyboxBuildingsScale, skyboxBuildingsScale, skyboxBuildingsScale)
    skyboxBuildings1.position.y -= 10
    skyboxBuildings1.updateMatrix()
    skyboxBuildings2.scale.set(skyboxBuildingsScale, skyboxBuildingsScale, skyboxBuildingsScale)
    skyboxBuildings2.position.y -= 10
    skyboxBuildings2.updateMatrix()

  }

  /**
   * Vytvorenie vlajok
   */
  private createFlags(): void {

    const mesh = game.getMesh('flags_Flag_Base')
    const material = mesh.material as THREE.MeshBasicMaterial
    this.flagsLightMap = material.lightMap

    for (let i = 1; i <= 10; i += 1) {

      const clonedMesh = mesh.clone()
      clonedMesh.geometry = mesh.geometry.clone()
      clonedMesh.matrixAutoUpdate = true
      clonedMesh.name = `${mesh.name}_cloned_${i}`
      const stringNumber = i < 10 ? `0${i}` : i
      const object3D = game.getObject3D(`Flag_0${stringNumber}`)
      object3D.scale.set(1, 1, 1)
      object3D.add(clonedMesh)
      clonedMesh.rotation.x = -Math.PI / 2
      clonedMesh.rotation.y = 0
      const random = THREE.MathUtils.randInt(0, 8)
      this.flagMeshesOptions.push({
        mesh: clonedMesh,
        indexFrom: random,
        indexTo: random + 1,
        actualRotation: 1
      })

      // musime dat na nulu prvy influence, lebo nam to bude robit potom problemy
      if (clonedMesh.morphTargetInfluences) clonedMesh.morphTargetInfluences[0] = 0

      this.changeUVs(
        clonedMesh,
        flagsConfig.flagsToUse[i - 1].x,
        flagsConfig.flagsToUse[i - 1].y
      )

    }

    mesh.visible = false

  }

  /**
   * Vytvorenie vody
   */
  private createWater(): void {

    this.waterMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTexture1: {
          value: game.texturesToUse.main.get(TexturesNames.voronoiWater)
        },
        uTime: {
          value: 0
        },
        distanceBias: {
          value: 1
        },
        timeFactor: {
          value: 11// 18
        },
        waterDir: {
          value: new THREE.Vector2(1, 0.5)
        },
        specularTreshold: {
          value: 0.9
        },
        surfaceColor: {
          value: new THREE.Color(0x053e61)
        },
        fresnelColor: {
          value: new THREE.Color(0xccfaff)
        },
        baseColorStr: {
          value: 1
        },
        baseColorPow: {
          value: 1.0
        },
        waterOpacity: {
          value: 0.95
        },
        fresnelOffset: {
          value: 0.9
        },
        fresnelPower: {
          value: 5.0
        }
      },
      vertexShader: waterVertexShader,
      fragmentShader: waterFragmentShader
    })

    this.waterMaterialAlternative = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0x063D5F).convertSRGBToLinear()
    })

    this.waterMaterial.transparent = true
    this.waterMaterial.needsUpdate = true
    this.waterPlane = game.getObject3D('env_Water_K1') as THREE.Mesh
    this.waterPlane.material = this.waterMaterial
    this.waterPlane.renderOrder = 2
    this.waterPlane.matrixAutoUpdate = true

  }

  /**
   * Nastavenie veci pre vodu podla kvality
   * @param quality - Kvalita
   */
  public setWaterSettingsByQuality(quality: number): void {

    // transparentnost
    this.waterMaterial.transparent = quality >= 6

    // aky material bude mat voda
    this.waterPlane.material = quality >= 3 ? this.waterMaterial : this.waterMaterialAlternative

    // zobrazenie alebo schovanie vlniek
    this.wakePlane.visible = quality >= 7

  }

  /**
   * Akutalizacia shadera vody
   * @param newValue - Hodnota casu
   */
  public updateWater(newValue: number): void {

    const quality = gameSettingsState().graphicsSettings

    // pri kvalite 5+ davame pohyblivu vodu
    if (quality >= 3) this.waterMaterial.uniforms.uTime.value = newValue

    // pri kvalite 7+ davame aj vlny
    if (quality >= 7) {

      this.speedLerpHelper.x = player.speedManager.getActualSpeed() / 7
      this.speedLerp.lerp(this.speedLerpHelper, 0.01)

      this.waterWakeMaterial.uniforms.uTime.value = newValue * disciplinePhasesManager.phaseRunning.speedLockCoef
      this.waterWakeMaterial.uniforms.uPosition.value = player.getPosition().clone()
      this.waterWakeMaterial.uniforms.uSpeed.value = this.speedLerp.x

    }

    // this.waterSplashesMaterials[0].uniforms.uTime.value = newValue - this.splashesTimestamps[0]
    // this.waterSplashesMaterials[1].uniforms.uTime.value = newValue - this.splashesTimestamps[1]

  }

  /**
   * Zmena fresnelu v shaderi pre vodu
   */
  public changeWaterFresnel(): void {

    this.waterMaterial.uniforms.fresnelOffset.value = 0.99
    this.waterMaterial.uniforms.fresnelPower.value = 40.0
    this.waterMaterial.uniforms.distanceBias.value = 2.0

  }

  /**
   * Vytvorenie vln na kajakom
   */
  private createWaterWake(): void {

    this.waterWakeMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uPosition: {
          value: new THREE.Vector3(0.0, 0.0, 0.0)
        },
        uTexture1: {
          value: game.texturesToUse.main.get(TexturesNames.wake)
        },
        uTime: {
          value: 0
        },
        distanceBias: {
          value: 1
        },
        timeFactor: {
          value: 50// 18
        },
        waterDir: {
          value: new THREE.Vector2(1, 0.5)
        },
        specularTreshold: {
          value: 0.9
        },
        surfaceColor: {
          value: new THREE.Color(0xffffff)
        },
        baseColorStr: {
          value: 1
        },
        baseColorPow: {
          value: 1.0
        },
        uSpeed: {
          value: 0.1
        }
      },
      vertexShader: wakeVertexShader,
      fragmentShader: wakeFragmentShader
    })
    this.waterWakeMaterial.transparent = true
    this.waterWakeMaterial.blending = THREE.AdditiveBlending
    this.waterWakeMaterial.needsUpdate = true

    this.wakePlane = game.getObject3D('WaterEffects_K1_Wake') as THREE.Mesh
    this.wakePlane.material = this.waterWakeMaterial
    this.wakePlane.matrixAutoUpdate = true
    this.wakePlane.position.set(0, 0.105, 2.7)
    this.wakePlane.renderOrder = 3
    player.athleteObject.add(this.wakePlane)

  }

  /**
   * Vytvorenie splechotu na kajakom
   */
  private createWaterSplashes(): void {

    const meshSplashes = game.getObject3D('WaterEffects_K1_Splashes') as THREE.Mesh

    for (let i = 0; i <= 1; i += 1) {

      this.waterSplashesMaterials[i] = new THREE.ShaderMaterial({
        uniforms: {
          uTexture1: {
            value: game.texturesToUse.main.get(TexturesNames.wake)
          },
          uTime: {
            value: 0
          },
          distanceBias: {
            value: 1
          },
          timeFactor: {
            value: 11// 18
          },
          waterDir: {
            value: new THREE.Vector2(1, 0.5)
          },
          specularTreshold: {
            value: 0.9
          },
          surfaceColor: {
            value: new THREE.Color(0xffffff)
          },
          baseColorStr: {
            value: 2
          },
          baseColorPow: {
            value: 1.0
          },
          waterOpacity: {
            value: 0.95
          },
        },
        vertexShader: splashesVertexShader,
        fragmentShader: splashesFragmentShader
      })
      this.waterSplashesMaterials[i].transparent = true
      this.waterSplashesMaterials[i].blending = THREE.AdditiveBlending
      this.waterSplashesMaterials[i].needsUpdate = true
      this.splashesPlanes[i] = i === 0 ? meshSplashes.clone() : meshSplashes
      this.splashesPlanes[i].material = this.waterSplashesMaterials[i]
      this.splashesPlanes[i].matrixAutoUpdate = true
      game.scene.add(this.splashesPlanes[i])
      this.splashesPlanes[i].visible = false
      this.splashesPlanes[i].renderOrder = 6

    }

  }

  /**
   * Zobrazenie splechu na pozicii
   * @param position - Pozicia
   */
  public showSplashes(position: THREE.Vector3): void {

    position.y += 0.05
    this.splashesPlanes[this.splashesActualIndex].position.copy(position)
    this.splashesPlanes[this.splashesActualIndex].visible = true
    this.splashesTimestamps[this.splashesActualIndex] = this.clock.getElapsedTime()

    this.splashesActualIndex += 1
    if (this.splashesActualIndex > 1) this.splashesActualIndex = 0

  }

  /**
   * Vytvorenie ciary pre lidra
   */
  private createLineLeader(): void {

    this.lineLeader = game.getMesh('env_LeadIndicator_Line')
    this.lineLeader.position.y = 3.008
    this.lineLeader.position.z = 37.1
    this.lineLeader.matrixAutoUpdate = true

    this.setVisibilityLineLeader(false)

  }

  /**
   * Nastavenie viditelnosti pre ciaru pre lidra
   * @param visibility - Viditelnost
   */
  public setVisibilityLineLeader(visibility: boolean): void {

    this.lineLeader.visible = visibility

  }

  /**
   * Nastavenie viditelnosti pre cielovu stenu
   * @param visibility - Viditelnost
   */
  public setVisibilityFinishWall(visibility: boolean): void {

    this.finishWall.visible = visibility

  }

  /**
   * Kontrolovanie veci pre lidra
   */
  public manageLeader(): void {

    const notPossibleStates = [
      PlayerStates.prepare,
      PlayerStates.prepare2,
      PlayerStates.prepareSpecial,
      PlayerStates.ready,
    ]
    if (
      disciplinePhasesManager.phaseRunning.speedLockActive ||
      player.isOneOfStates(notPossibleStates) ||
      endManager.isSomeoneInFinish() ||
      modes.isTrainingMode()
    ) {

      this.setVisibilityLineLeader(false)
      return

    }

    // podla z pozicie, nie percenta, lebo ciary su rozne dlhe
    const playerZ = player.athleteObject.position.z
    let bestZ = 0
    opponentsManager.getOpponents().forEach((opponent) => {

      const z = opponent.athleteObject.position.z
      if (z > bestZ) {

        bestZ = opponent.athleteObject.position.z

      }

    })

    let color: LeaderColors = LeaderColors.lightBlue

    // ak bol skor hrac, tak robime nejake veci
    if (playerZ >= bestZ) {

      bestZ = playerZ
      if (player.speedManager.isActive()) {

        color = LeaderColors.green

      }

    }

    // pohneme vsetkou grafikou

    this.lineLeader.position.z = bestZ + lungeConfig.lungeOffsetMin

    // nastavime farbu pre lidra
    const leaderMaterial = this.lineLeader.material as THREE.MeshBasicMaterial
    leaderMaterial.color.set(gameConfig.colorsLeader[color])

  }

  /**
   * Zmena UV
   * @param mesh - Mesh na ktorom menime UVcka
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   */
  private changeUVs(mesh: THREE.Mesh, indexU: number, indexV: number): void {

    const uvAttribute = mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = uvAttribute.getX(i) + (flagsConfig.horizontalValue * indexU)
      const v = uvAttribute.getY(i) + (flagsConfig.verticalValue * indexV)

      uvAttribute.setXY(i, u, v)

    }

    uvAttribute.needsUpdate = true

  }

  /**
   * Hybanie vlajkami
   */
  private waveFlags(): void {

    let nullifyProgress = false
    this.flagMeshesOptions.forEach(({ mesh, indexFrom, indexTo, actualRotation }, index) => {

      if (!mesh.morphTargetInfluences) return

      mesh.morphTargetInfluences[indexFrom] = 1 - this.progress
      mesh.morphTargetInfluences[indexTo] = this.progress

      mesh.rotateY(this.flagMeshesOptions[index].actualRotation > 0 ?
        flagsConfig.rotationSpeed :
        -flagsConfig.rotationSpeed)


      if (this.progress >= 1) {

        mesh.morphTargetInfluences[indexFrom] = 0
        mesh.morphTargetInfluences[indexTo] = 1

        this.flagMeshesOptions[index].indexFrom += 1
        if (this.flagMeshesOptions[index].indexFrom >= 10) this.flagMeshesOptions[index].indexFrom = 0

        this.flagMeshesOptions[index].indexTo += 1
        if (this.flagMeshesOptions[index].indexTo >= 10) this.flagMeshesOptions[index].indexTo = 0


        const changeDir = Math.random() > flagsConfig.rotationChangeProbability

        if (changeDir || mesh.rotation.y > flagsConfig.rotationMax || mesh.rotation.y < flagsConfig.rotationMin) {

          this.flagMeshesOptions[index].actualRotation = actualRotation * -1

        }

        nullifyProgress = true

      }

    })

    if (nullifyProgress) this.progress = 0

    this.progress += (flagsConfig.updateSpeed / fpsManager.fpsLimit)

  }

  /**
   * Aktualizacia vlajok
   */
  public updateFlags(): void {

    if (!this.movingFlagsEnabled) return

    this.waveFlags()

    if (this.flagsLightMap) this.flagsLightMap.offset.x -= flagsConfig.lightMapSpeed

  }

  /**
   * Zaklopenie startovacich zarazok
   */
  public updateStartlineBlockers(): void {

    if (!this.startlineBlockersActive) return

    this.startlineBlockers.rotation.x += 0.1

    if (this.startlineBlockers.rotation.x > 1) {

      this.startlineBlockersActive = false

    }

  }

  /**
   * Resetovanie startovacich zarazok
   */
  public resetStartlineBlockers(): void {

    this.startlineBlockersActive = false
    this.startlineBlockers.rotation.x = this.startLineBlockersOrigRotationX

  }

  /**
   * Posuvanie vzducholode
   */
  public manageBlimp(): void {

    this.blimpMesh.position.sub(this.blimpDir)

  }

  /**
   * Pohyb bojok
   */
  public manageBuoys(): void {

    const addValue = this.buoysMovementDir * 0.0025
    const moveStartBuoys = player.getActualPercentOnPath() <= pathsConfig.positionsStart[0] + 5

    this.buoysActualOffset += addValue
    this.buoysMesh.position.y += addValue
    if (moveStartBuoys) this.buoysMeshStart.position.y += addValue

    if (
      (this.buoysMovementDir === 1 && this.buoysActualOffset >= this.buoysActualMax) ||
      (this.buoysMovementDir === -1 && this.buoysActualOffset <= -this.buoysActualMax)
    ) {

      this.buoysMovementDir *= -1
      this.buoysActualOffset -= addValue
      this.buoysMesh.position.y -= addValue
      if (moveStartBuoys) this.buoysMeshStart.position.y -= addValue

      this.buoysActualMax = THREE.MathUtils.randFloat(0.02, 0.04)

    }

  }

  /**
   * Sprava oblakov
   */
  public manageSkyboxClouds() {

    if (!this.skyboxCloudsMesh || !this.movingSkyboxEnabled) return
    this.skyboxCloudsMesh.rotateY(-gameConfig.skyboxCloudsOffsetStep)

  }

}

export const worldEnv = new WorldEnv()
