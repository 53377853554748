import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'tutorialText15-1',
    replace: [
      {
        original: '[number]',
        new: '200'
      }
    ]
  },
  {
    text: 'tutorialText15-2',
    replace: [
      {
        original: '[start]',
        new: 'START'
      }
    ]
  },

]
