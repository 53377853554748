/**
 * Konfig pre vlajky
 */
export const flagsConfig = {

  // horizontalna hodnota posunu pre offset
  horizontalValue: 0.125,

  // vertikalna hodnota posunu pre offset
  verticalValue: 0.083007,

  // rychlost akou sa vlni lightmapa
  lightMapSpeed: 0.005,

  // rychlost aktualizacie vlajok (bude sa to delit este fps limitom)
  updateSpeed: 4,

  // rychlost rotacie vlajok
  rotationSpeed: 0.002,

  // moznost zmeny rotacie pocast rotovania
  rotationChangeProbability: 0.8,

  // minimalna rotacia
  rotationMin: -0.5,

  // maximalna rotacia
  rotationMax: 0.5,

  // ake vlajky sa pouziju
  flagsToUse: [
    // Australia
    {
      x: 0,
      y: 1
    },
    // Belgium
    {
      x: 5,
      y: 1
    },
    // China
    {
      x: 2,
      y: 0
    },
    // Finland
    {
      x: 3,
      y: 1
    },
    // France
    {
      x: 3,
      y: 0
    },
    // Great Britain
    {
      x: 1,
      y: 0
    },
    // Italy
    {
      x: 4,
      y: 0
    },
    // Netherlands
    {
      x: 5,
      y: 1
    },
    // South Korea
    {
      x: 4,
      y: 1
    },
    // Spain
    {
      x: 6,
      y: 2
    },
    // Ukraine
    {
      x: 4,
      y: 3
    },
    // United States
    {
      x: 0,
      y: 0
    },
  ]

}
