import { defineStore } from 'pinia'

export interface PlayerState {
  maxSpeed: number,
  speed: number,
  strokeQuality: number
}

const initialState = (): PlayerState => ({
  maxSpeed: 1,
  speed: 0,
  strokeQuality: 0
})

export const mainState = defineStore('mainState', {
  state: initialState,
})
