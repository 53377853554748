<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div
        v-show="!modalActive && !trainingState.firstTutorialMessage"
        id="main-ui"
      >
        <hud-component v-show="showDebug" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <emotion-messages />
        <text-message
          v-if="textMessageState.showMessage"
          :style="textMessageStyle"
          :first-line-text="textMessageState.firstLineText"
          :first-line-sprite-type="textMessageState.firstLineTextType"
          :second-line-text-type="textMessageState.secondLineTextType"
          :second-line-left-number="textMessageState.secondLineLeftNumber"
          :show-first-line="textMessageState.showFirstLine"
          :show-second-line="textMessageState.showSecondLine"
          :with-animation="true"
          :show-type="textMessageState.showType"
          :delay-to-hide="0.3"
          :duration-hide="[0.5, 0.5, 0.5, 0.5]"
          :duration-translation="[0.5, 0.5, 0.5 * 2, 0.5]"
          @finished-first-line="finishedFirstLine"
          @finished-second-line="finishedSecondLine"
        />
        <boxes-records />
        <phase-start />
        <start-debug-bar />
        <under-menu-component />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
        />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <data-table />
        <text-message
          v-if="lungeState.messageType !== ''"
          style="position:absolute;left:50%; transform-origin: center top;"
          :style="{top: isMobile ? '15%' : '7%'}"
          :first-line-text="lungeMessageText"
          show-first-line
          :show-type="3"
        />
        <text-message
          v-if="textMessageState.showTogo"
          :key="textMessageState.showType"
          style="position:absolute;left:50%; top: 15%"
          first-line-text="kayak-togo-texts"
          :first-line-sprite-type="textMessageState.showType"
          show-first-line
          with-animation
          :show-type="3"
        />
        <lunge-info
          v-if="lungeState.showLungeInfo"
          :text="lungeState.text"
          :fill="lungeState.fill"
          :color="lungeState.color"
          :show-info="lungeState.showInfo"
          :text-time-lunge="$t('timeLastStroke')"
        />
        <bottom-hud
          v-if="lapPositionState.showPosition"
        />
        <start-arrow v-if="startMessageState.showStartArrow" />
        <info-keys v-if="!isMobile" />
        <black-overlay />
      </div>
      <traffic-component />
      <fps-component />
      <tutorial-logic-component v-show="!modalActive" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-ssm="isAppSSM2023"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="blurState.isActive && !blurState.isToggle"
        :opacity="0"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
    </div>
    <loading />
    <white-shine-component />
  </div>
</template>

<script lang="ts">
// External Package codes

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
  LungeInfo,
  TextMessage,
  loadingState,
  loadingCircleState
} from '@powerplay/core-minigames-ui-ssm'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import TrafficComponent from '@/components/TrafficComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import UnderMenuComponent from '@/components/UnderMenuComponent/index.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import WhiteShineComponent from '@/components/WhiteShineComponent.vue'
import StartDebugBar from '@/components/StartDebugBar.vue'
import BlackOverlay from '@/components/BlackOverlay.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { endManager } from '@/app/EndManager'

import { defineComponent } from 'vue'
import EmotionMessages from '../EmotionMessages.vue'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import { gameConfig } from '@/app/config/gameConfig'
import BoxesRecords from '../BoxesRecords.vue'
import InfoKeys from '../InfoKeys.vue'
import {
  blurState,
  type BlurState,
  lungeState,
  uiState,
  textMessageState,
  trainingResultsState,
  trainingState,
  lapPositionState,
  startMessageState,
} from '@/stores'
import BottomHud from '../BottomHud.vue'
import StartArrow from '../StartArrow.vue'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    ErrorModal,
    DataTable,
    InfoComponent,
    SpeedMeterComponent,
    FpsComponent,
    TrafficComponent,
    TrainingLayout,
    TrainingResults,
    UnderMenuComponent,
    TutorialLogicComponent,
    LoadingCircleOverlay,
    TrainingOverride,
    ColorOverlayComponent,
    WhiteShineComponent,
    StartDebugBar,
    LungeInfo,
    BlackOverlay,
    EmotionMessages,
    TextMessage,
    BoxesRecords,
    InfoKeys,
    BottomHud,
    StartArrow
  },
  data() {

    return {
      modalActive: false,
      isAppSSM2023: false,
      blurState: blurState(),
      lungeState: lungeState(),
      uiState: uiState(),
      textMessageState: textMessageState(),
      trainingResultsState: trainingResultsState(),
      trainingState: trainingState(),
      lapPositionState: lapPositionState(),
      startMessageState: startMessageState(),
    }

  },
  computed: {
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !trainingResultsState().showResults

    },
    textMessageStyle(): unknown {

      return {
        position: 'absolute',
        left: '50%',
        top: '20%',
        'transform-origin': 'top'
      }

    },
    showDebug() {

      return gameConfig.showHUD

    },
    getLungeStyle() {

      return {
        position: 'absolute',
        'justify-content': 'flex-end',
        width: '100%',
        bottom: '14%',
        'transform-origin': 'center bottom'
      }

    },
    lungeMessageText() {


      return `lunge-${lungeState().messageType}`

    }
  },
  watch: {
    blurState: {
      handler(value: BlurState) {

        const blurClass = modes.isTutorial() ? 'blur-class-v2' : 'blur-class'

        if (value.isActive) {

          document.getElementsByTagName('canvas')[0].classList.add(blurClass)

        } else {

          document.getElementsByTagName('canvas')[0].classList.remove(blurClass)

        }

        // osetrenie vypnutia menu pocas zobrazenej tabulky
        if (!value.isActive && value.isTable) {

          blurState().isActive = true

        }

      },
      deep: true
    },
  },
  created(): void {

    loadingState().$patch({
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppSSM2023 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)

  },
  methods: {
    showLoading(): boolean {

      return loadingState().showLoading

    },
    closeApp(): void {

      endManager.prematureEnded = true
      loadingCircleState().isActive = true
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        blurState().$patch({
          isActive: true,
          isToggle: true
        })
        inputsManager.actionInputsBlocked = true
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        blurState().$patch({
          isActive: false,
          isToggle: false
        })
        game.resumeGame()

      }

    },
    finishedFirstLine() {

      startPhaseStateManager.hideTextMessage(1)

    },
    finishedSecondLine() {

      startPhaseStateManager.hideTextMessage(2)

    },
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
</style>
<style lang="less" scoped>
.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}
</style>
