<template>
  <section
    class="positioner-mobile-inputs"
  >
    <section
      v-if="inputsState.isVisible && !gameSettingsState.oneFinger"
    >
      <movement-sides
        :disabled="inputsState.disabled"
        ignore-safe-zones
        type="paddle"
        @position="changeMovement"
      />
    </section>
    <section
      v-if="inputsState.isVisible && gameSettingsState.oneFinger"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between fixed"
      >
        <section v-show="!actionButtonState.showJoystick" />
        <section style="position: fixed; bottom: 0; right: 0">
          <movement-buttons
            type="paddle"
            class="bottom-right"
            :orientation="'horizontal'"
            @position="changeMovement"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section style="position: fixed; bottom: 0; left: 0">
          <movement-buttons
            type="paddle"
            class="bottom-left"
            :orientation="'horizontal'"
            @position="changeMovement"
          />
        </section>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MovementSides,
  WindowAspect,
  MovementButtons,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import { Sides } from '@/app/types/player'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  actionButtonState,
  inputsState
} from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementSides,
    MovementButtons
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      inputsState: inputsState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    transformCoef() {

      return `scale(${ this.scaleCoef })`

    }
  },
  methods: {
    changeMovement(positionX: number) {

      let side = undefined
      if (positionX > 0) side = Sides.RIGHT
      if (positionX < 0) side = Sides.LEFT
      disciplinePhasesManager.phaseRunning.handleInputs(side)
      disciplinePhasesManager.phaseStart.handleInputs(side)

    }
  }
})

</script>

<style lang="less">
.positioner-mobile-inputs {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  pointer-events: auto;
  transform: none;

  .movement-sides-container {
    .power-mobile-button-ssm {
      pointer-events: auto;

      .button-element {
        .image {
          height: 280px;
          width: 280px;
        }
      }
    }
  }
  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .relative {
    position: relative;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }

  .action-sides-container {
      height: 580px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;

      &.disabled {
          opacity: 0.7;
      }

      .power-mobile-button{
          .button-element{
              .image{
                  height: 100px;
                  width: 100px;
                  background-size: cover;
                  left: 50%;
                  margin-left: -50px;
              }
          }
      }

      .left-side{
          display: flex;
          position: fixed;
          left: 0;
          bottom: 0;
          transform-origin: bottom left;

          .left-side-button {
              .button-element {
                  .image {
                      margin-left: -30px;
                  }
              }
          }
      }

      .right-side{
          display: flex;
          position: fixed;
          right: 0;
          bottom: 0;
          transform-origin: bottom right;

          .right-side-button {
              .button-element {
                  .image {
                      margin-left: 20px;
                  }
              }
          }
      }

  }
  .bottom-right {
    transform-origin: bottom right;
  }
  .bottom-left {
    transform-origin: bottom left;
  }
}
</style>
