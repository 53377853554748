import {
  audioManager,
  gsap
} from '@powerplay/core-minigames'
import { AudioNames } from '../types'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  /** Pocet frameov, kolko chyba do zmenu zvuku audience po starte */
  private framesChangeAudienceAfterStart = 0

  /** Pocet frameov, kolko chyba do zmenu zvuku audience v cieli */
  private framesChangeAudienceAfterFinish = 0

  /** Ci sa uz nastavili veci po cieli */
  private afterFinishSet = false

  /**
   * Zastavenie zvuku s delayom
   * @param name - Meno zvuku
   * @param delay - Delay pre zastavenie zvuku
   */
  public stopAudioWithDelay(name: AudioNames, delay = 0.5): void {

    gsap.to(
      {},
      {
        duration: delay,
        onComplete: () => {

          audioManager.stopAudioByName(name)

        }
      }
    )

  }

  /**
   * Nastavenie timera pre zmenu zvukov audience po starte
   */
  public setTimerToChangeAudienceAfterStart(): void {

    this.framesChangeAudienceAfterStart = 10

  }

  /**
   * Nastavenie timera pre zmenu zvukov audience v cieli
   */
  public setTimerToChangeAudienceAfterFinish(): void {

    if (this.afterFinishSet) return

    this.framesChangeAudienceAfterFinish = 10
    this.afterFinishSet = true

  }

  /**
   * Kontrola, ci netreba prepnut divakov po X frameoch po starte
   */
  private checkChangeAudienceAfterStart(): void {

    if (this.framesChangeAudienceAfterStart === 0) return

    this.framesChangeAudienceAfterStart -= 1

    if (this.framesChangeAudienceAfterStart === 0) {


      const audio = disciplinePhasesManager.phaseStart.wasFalseStart ?
        AudioNames.audienceBad :
        AudioNames.audienceHyped
      audioManager.play(audio)
      audioHelper.stopAudioWithDelay(AudioNames.audienceHyped2)

    }

  }

  /**
   * Kontrola, ci netreba prepnut divakov po X frameoch v cieli
   */
  private checkChangeAudienceAfterFinish(): void {

    if (this.framesChangeAudienceAfterFinish === 0) return

    this.framesChangeAudienceAfterFinish -= 1

    if (this.framesChangeAudienceAfterFinish === 0) {

      audioManager.play(AudioNames.audienceHyped2)
      this.stopAudioWithDelay(AudioNames.audienceHyped)

    }

  }

  /**
   * updatujeme kazdy frame pri niektorych disciplinach
   * kde potrebujeme ratat framy pre spustanie zvukov
   */
  public update(): void {

    this.checkChangeAudienceAfterStart()
    this.checkChangeAudienceAfterFinish()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.afterFinishSet = false
    this.framesChangeAudienceAfterFinish = 0
    this.framesChangeAudienceAfterStart = 0

  }

}

export const audioHelper = new AudioHelper()
