/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Veci ohladom rychlosti v cieli */
  speedOnEnd: {

    /** Minimalna rychlost */
    minSpeed: 4,

    /** Pocet frameov, za kolko sa dostane na min speed pri emocii */
    framesToMinSpeed: 60,

    /** Randomizovanie konca, tj kolko frameov moze byt menej alebo viac oproti prvemu spomalovaniu */
    randomizeEnd: {

      minus: 0,
      plus: 60

    }

  },

  /** ako casto padlujeme */
  paddleFrequency: 22,

  /** ako casto padlujeme */
  firstIncorrectInputPaddleFrequency: 22,

  /** max boost */
  maxBoost: 1.5,

  /** cruisecoef */
  cruiseCoef: 1,

  /** ako casto znizovat */
  speedAutoDecreaseFrames: 1,

  /** o kolko znizovat */
  speedAutoDecreaseValue: 0.05,

  /** start speed */
  startSpeed: 2,

  /** koeficient pre nespravnu sipku pri prvom inpute */
  firstIncorrectInputCoef: 0.5,

  /** topSpeed */
  topSpeed: 6.5,

  strokeQualityScore: {
    perfect: {
      min: 99,
      newFrames: 16
    },
    excellent: {
      min: 91,
      newFrames: 18
    },
    good: {
      min: 79,
      newFrames: 20
    },
    poor: {
      min: 0,
      newFrames: 22
    }
  }

}
