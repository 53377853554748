import { fpsManager } from '@powerplay/core-minigames'
import {
  startConfig,
  velocityConfig
} from '../config'
import type { Athlete } from '../entities/athlete'

/**
 * Manager rychlosti hraca
 */
export class SpeedManager {

  /** ci je speedManager aktivny */
  private active = false

  /** momentalna rychlost v m/s */
  private actualSpeed = 0

  /** momentalna rychlost v cieli v m/s */
  private finishSpeedActual = 0

  /** hodnota na odcitanie rychlosti v cieli */
  private finishSpeedSub = 0

  /** minimalna hodnota rychlosti v cieli */
  private finishSpeedMin = 0

  /** Hodnota pre znizenie kazdy frame pri false starte, aby hrac spomaloval */
  private falseStartSpeedDecrease = 0

  /** topspeed - neskor podla atributu */
  public topSpeed = 0

  /** cruiseSpeed */
  public cruiseSpeed = 0

  /** frameCounter */
  private frameCounter = 0

  /**
   * Vracia rychlost v metroch za sekundu
   * @returns actualSpeed m/s
   */
  public getActualSpeed(): number {

    return this.actualSpeed

  }

  /**
   * Setter
   * @param speed - nova rychlost
   */
  public setActualSpeed(speed: number): void {

    this.actualSpeed = speed
    this.actualSpeed = Math.max(0, this.actualSpeed)

  }

  /**
   * Nastavenie rychlosti
   * @param strength - sila atleta
   * @param athlete - athlete
   */
  public setSpeeds(strength: number, athlete: Athlete) {

    this.topSpeed = velocityConfig.topSpeed
    if (strength < 100) {

      this.topSpeed = 4.95 + (strength * 0.003)

    } else if (strength < 1000) {

      this.topSpeed = 5.25 + ((strength - 100) * 0.0015)

    } else {

      this.topSpeed = 6.6 + ((strength - 1000) * 0.00025)

    }
    console.log(`${athlete.uuid } topSpeed: ${ this.topSpeed}`)
    this.cruiseSpeed = this.topSpeed - velocityConfig.cruiseCoef

  }

  /**
   * Nastavi specialne spravanie rychlosti pre false start
   */
  public setSpeedFalseStart(): void {

    this.falseStartSpeedDecrease = startConfig.falseStart.decreaseSpeedValue / fpsManager.fpsLimit

  }

  /**
   * Vracia rychlost v metroch za frame
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrame(): number {

    return this.actualSpeed / fpsManager.fpsLimit

  }

  /**
   * Vracia rychlost v metroch za frame v cieli
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrameOnEnd(): number {

    this.finishSpeedActual -= this.finishSpeedSub
    if (this.finishSpeedActual < this.finishSpeedMin) this.finishSpeedActual = this.finishSpeedMin

    return this.finishSpeedActual / fpsManager.fpsLimit

  }

  /**
   * Nastavenie dat pre spomalovanie v cieli
   * @param from - Pociatocna rychlost
   * @param to - Konecna rychlost
   * @param frames - Pocet frameov na dosiahnutie konecnej rychlosti
   */
  public setDataForSpeedOnEnd(
    from: number,
    to: number,
    frames: number,
  ): void {

    this.finishSpeedActual = from
    this.finishSpeedMin = to
    this.finishSpeedSub = (from - to) / frames

  }

  /**
   * Setter active
   * @param active - active
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /**
   * Ziskanie localMaxSpeed
   * @param minIdealValue - Minimalna hodnota idealu
   * @param globalMaxSpeed - globalMaxSpeed
   * @returns localMaxSpeed
   */
  public getLocalMaxSpeed(minIdealValue: number, globalMaxSpeed: number): number {

    return (0.5 + ((minIdealValue - 110) / 160)) * globalMaxSpeed

  }

  /**
   * Sprava znizovania rychlosti pri false starte
   * @returns True, ak sa znizuje rychlost
   */
  private manageFalseStartSpeedDecrease(): boolean {

    if (this.falseStartSpeedDecrease <= 0) return false

    const minSpeed = startConfig.falseStart.minSpeed
    this.actualSpeed -= this.falseStartSpeedDecrease
    if (this.actualSpeed < minSpeed) this.actualSpeed = minSpeed

    return true

  }

  /**
   * Aktualizovanie veci kazdy frame
   */
  public update(): void {

    if (this.manageFalseStartSpeedDecrease()) return

    this.frameCounter += 1
    if (this.actualSpeed > this.cruiseSpeed && (this.frameCounter % velocityConfig.speedAutoDecreaseFrames === 0)) {

      this.frameCounter = 0
      this.setActualSpeed(this.actualSpeed - velocityConfig.speedAutoDecreaseValue)

    }
    // this.setSpeed(speedbarValue, minIdealValue, attribute)

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualSpeed = 0
    this.active = false
    this.falseStartSpeedDecrease = 0
    this.finishSpeedActual = 0
    this.finishSpeedSub = 0
    this.finishSpeedMin = 0

  }

}
