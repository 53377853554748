import {
  type TutorialObjective,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    yellowText: string,
    yellowTextSpecial: boolean,
    offset: boolean
  },
  objectives: TutorialObjective[],
  settings: boolean,
  buttons: {
    showMovementButtons: boolean,
    showJoystick: boolean
  },
  showButtonStart: boolean,
  showUnderMenu: boolean,
  showBarStart: boolean,
  showBarSpeed: boolean,
  showArrowAim: boolean,
  instructionBoxText: string,
  instructionBoxIcon: number,
  showInstructionBox: boolean,
  showSpeedBar: boolean,
  goodPaddles: number
}

const initialState = (): TutorialState => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    yellowText: '',
    yellowTextSpecial: false,
    offset: false
  },
  settings: false,
  objectives: [],
  buttons: {
    showMovementButtons: false,
    showJoystick: false
  },
  showButtonStart: false,
  showUnderMenu: false,
  showBarStart: false,
  showBarSpeed: false,
  showArrowAim: false,
  instructionBoxText: '',
  instructionBoxIcon: -1,
  showInstructionBox: false,
  showSpeedBar: false,
  goodPaddles: 0
})

export const tutorialState = defineStore('tutorialState', {
  state: initialState,
})
