import {
  pathAssetsKayak,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDirKayak = `${pathAssetsKayak}/models/`
const modelsDirGlobal = `${pathAssetsGlobal}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.athlete]: {
    ext: 'glb',
    genderActive: true,
    dir: modelsDirKayak,
    version: 240827,
    femaleVersion: 240827,
    mainMeshNames: [ModelsNames.athlete],
    opponentCheck: true
  },
  [ModelsNames.env]: {
    ext: 'glb',
    version: 2409122,
    dir: modelsDirKayak
  },
  [ModelsNames.trackLines]: {
    ext: 'glb',
    version: 240621,
    dir: modelsDirKayak
  },
  [ModelsNames.skybox]: {
    ext: 'glb',
    version: 2311092,
    dir: modelsDirGlobal
  },
  [ModelsNames.waterEffects]: {
    ext: 'glb',
    version: 240912,
    dir: modelsDirGlobal
  },
  [ModelsNames.flags]: {
    ext: 'glb',
    version: 240808,
    dir: modelsDirKayak
  },
  [ModelsNames.notes]: {
    ext: 'glb',
    version: 240912,
    dir: modelsDirKayak
  },
  // [ModelsNames.cameraBoxes]: {
  //   ext: 'glb',
  //   version: 2403262,
  //   dir: modelsDirKayak,
  // }

}
