import { gameConfig } from '@/app/config'
import { gauss } from '@powerplay/core-minigames'

/**
 * Vietor
 */
export class Wind {

  /** Hodnota vetra */
  private actualValue = 0

  /**
   * Vratenie hodnoty vetra
   * @returns Vietor
   */
  public getValue(): number {

    return this.actualValue

  }

  /**
   * Nastavenie hodnoty vetra
   */
  public setValue(): void {

    this.actualValue = gauss.getValue(0, 10, -20, 20) / 10
    if (gameConfig.configWind.isEnabled) this.actualValue = gameConfig.configWind.value

    console.log('vygenerovany vietor je ', this.actualValue)

  }

}

export const wind = new Wind()
