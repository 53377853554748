import { defineStore } from 'pinia'

export interface PaddleArrowsState {
  left: string,
  right: string
}

const initialState = (): PaddleArrowsState => ({
  left: '',
  right: ''
})

export const paddleArrowsState = defineStore('paddleArrowsState', {
  state: initialState,
})
