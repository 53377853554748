import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(106.00119018554688, 35.8126335144043, 1086.8394775390625),
      endPosition: new THREE.Vector3(73.84745788574219, 33.55423355102539, 1126.774169921875),
      startRotation: new THREE.Vector3(1.5136394, 0.1846385, -1.2684614),
      endRotation: new THREE.Vector3(1.4996172, 0.1245941, -1.0494727),
      duration: 3,
      notSkippableFrames: 20,
      fov: 62.5
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(18.423372268676758, 5.802631855010986, 880.658264160156),
      endPosition: new THREE.Vector3(1.7650518417358398, 9.435782432556152, 915.2277221679688),
      startRotation: new THREE.Vector3(1.4512238, 0.1008025, -2.7792255),
      endRotation: new THREE.Vector3(1.4512238, 0.1008025, -2.7792255),
      duration: 3,
      notSkippableFrames: 20,
      fov: 50.7
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(-8.52113342285156, 8.827933311462402, 800.231506347656),
      endPosition: new THREE.Vector3(-11.572793006896973, 10.278261184692383, 853.4120483398438),
      startRotation: new THREE.Vector3(1.5755526, 0.0002827, -3.0819726),
      endRotation: new THREE.Vector3(1.5415721, -0.0016686, -3.0846141),
      fov: 50.7,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(-1.7689990997314453, 0.7242684364318848, 7.31744384765625),
      endPosition: new THREE.Vector3(-1.221395492553711, 0.6708931922912598, 4.97119140625),
      startRotation: new THREE.Vector3(1.549231, -0.0050533, 0.2292245),
      endRotation: new THREE.Vector3(1.549231, -0.0050533, 0.2292245),
      duration: 3,
      fov: 65.9,
      fovEnd: 50.7,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: {
      startPosition: new THREE.Vector3(-28.281702041625977, 3.485507011413574, 810.585693359375),
      endPosition: new THREE.Vector3(-18.331737518310547, 3.485507011413574, 810.585693359375),
      startRotation: new THREE.Vector3(1.5428095, -0.0584072, 1.1231183),
      endRotation: new THREE.Vector3(1.5428095, -0.0584072, 1.1231183),
      duration: 3,
      fov: 65.9
    },
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      // startPosition: new THREE.Vector3(-2, 2, 3),
      startPosition: new THREE.Vector3(6, 2, 0),
      // endPosition: new THREE.Vector3(2, 2, 3),
      endPosition: new THREE.Vector3(6, 2, 0),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,
  dollyZoom: {

    // aky velky bude rozdiel medzi pociatocnym a koncovym idealnym offsetom
    idealOffset: new THREE.Vector3(0, 1.2, -4.8),

    // koeficient pre upravu podla rychlosti
    speedCoef: 6.85,

    // alpha na vyhladenie
    alpha: 0.2

  },

  // cielova kamera
  finish: {

    // Delay vo frameoch, kedy sa az zobrazi tato kamera
    delayFrames: 25,

    // Hodnota na pridanie za frame na krivke
    addValuePerFrame: 0.0075,

    // pozicie pre kameru v cieli
    positions: [
      [
        new THREE.Vector3(-1.2, 0.4, 1.45),
        new THREE.Vector3(0, 0.45, 1.9),
        new THREE.Vector3(0.5, 0.5, 1.6)
      ],
      [
        new THREE.Vector3(0.5, 0.5, 1.6),
        new THREE.Vector3(0, 0.55, 2),
        new THREE.Vector3(-0.2, 1, 3)
      ]
    ]

  }

}
