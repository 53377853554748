/**
 * Konfig pre paths
 */
export const pathsConfig = {

  /** pocet drah */
  tracks: 8,

  /** percenta pre pociatocne pozicie hracov */
  positionsStartIntro: [
    0.832,
    0.832,
    0.832,
    0.832,
    0.832,
    0.832,
    0.832,
    0.832,
    0.832
  ],

  /** percenta pre pociatocne pozicie hracov */
  positionsStart: [
    0.84006,
    0.84006,
    0.84006,
    0.84006,
    0.84006,
    0.84006,
    0.84006,
    0.84006,
    0.84006
  ],

  /** kedy ma zacat finish faza podla % na krivke */
  positionsFinish: [
    0.9377,
    0.9377,
    0.9377,
    0.9377,
    0.9377,
    0.9377,
    0.9377,
    0.9377,
    0.9377
  ],

  /** kolko metrov max ukazujeme dopredu a dozadu v boxe */
  boatsDataOffset: 10

}
