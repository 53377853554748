<template>
  <div
    class="training-results layout"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <training-result
      :reward-data="rewardData"
      :training-name="name"
      :training-icon="icon"
      :show-train-again="trainingResultsState.showTrainAgain && otherSituations"
      :disabled-play-again-button="trainingResultsState.isDisabledPlayAgain"
      :high-score="trainingResultsState.bestScore"
      :is-new-high-score="isNewHighScore()"
      @button-click-play-again="onButtonClickPlayAgain()"
      @button-click-continue="onButtonClickContinue()"
    />
    <tutorial-training-rewards
      v-if="showTutorialRewards"
      :subheader="$t('tutorialAnneTableSubheader')"
      :rewards="rewardData.rewards"
      header-text="utTutorialBenus"
      instructor-name="benus2"
      :main-rewards="rewards"
      @on-click-continue="onTutorialRewardsButtonContinue()"
    />
  </div>
</template>

<script lang="ts">
import {
  TrainingResult,
  WindowAspect,
  TutorialTrainingRewards,
  gameState,
  waitingState,
  loadingState
} from '@powerplay/core-minigames-ui-ssm'
import {
  corePhasesManager,
  requestManager,
  gsap,
  trainingManager,
  playersManager,
  game,
  timeManager,
  modes,
  CameraStates,
audioManager
} from '@powerplay/core-minigames'
import type {
  ResponseInit,
  TrainingDataFromResultsRequest
} from '@powerplay/core-minigames'
import { pathAssetsCoreUi } from '@/globals/globalvariables'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { DisciplinePhases } from '@/app/types'
import { trainingTasks } from '@/app/modes/training'
import { endManager } from '@/app/EndManager'
import { finishCamera } from '@/app/phases/FinishPhase/FinishCamera'
import { getActivePinia } from 'pinia'

const createData = () => ({
  stars: 0,

  score: {
    base: 0,
    total: 0,
    bonuses: 0
  },

  bonus: {
    benefits: 0,
    alchemy: 0,
    subscription: 0
  },

  subscription: {
    active: 0,
    tp: 0
  },

  attribute: {
    value_from: 0,
    value_next: 0,
    tp_from: 0,
    tp_new: 0,
    tp_milestone: 0
  },

  energy: {
    value_new_game: 0,
    available: 0
  },

  rewards: [
  ]
})
import { defineComponent } from 'vue'
import { player } from '@/app/entities/athlete/player'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  blackOverlayState,
  trainingResultsState,
  type TrainingResultsState
} from '@/stores'

export default defineComponent({
  components: {
    TrainingResult,
    TutorialTrainingRewards
  },
  mixins: [WindowAspect],
  props: {
  },
  data() {

    return {
      gsapes: [] as gsap.core.Tween[],
      rewardData: createData(),
      rewards: [
        {
          type: 'TRAINING_POINTS',
          title: this.$t('tutorialRewardsTitle1'),
          text: this.$t('tutorialRewardsText1')
        },
        {
          type: 'experience',
          title: this.$t('tutorialRewardsTitle2'),
          text: this.$t('tutorialRewardsText2')
        },
      ],
      showTutorialRewards: false,
      trainingResultsState: trainingResultsState(),
    }

  },
  computed: {
    otherSituations() {

      return !this.isTrainer && !this.firstInstruction && !gameState().isPrematureEnd

    },
    firstInstruction() {

      return corePhasesManager.firstInstructions

    },
    isTrainer() {

      return modes.isTrainingWithTrainerMode()

    },
    name() {

      return this.$t(`disciplineName${requestManager.disciplineID}`)

    },
    icon() {

      return `${pathAssetsCoreUi}/table/attribute-icon/disc-ico-light-09.svg`

    }
  },
  watch: {

    trainingResultsState: {
      deep: true,
      handler(value: TrainingResultsState) {

        console.warn(this.trainingResultsState)
        if (!(value.showResults && value.dataSet)) return
        this.rewardData = createData()
        const data = value.data as TrainingDataFromResultsRequest

        const duration = 0.5
        const starsDuration = 0.3 * (data.stars ?? 0)

        let delay = duration + starsDuration + 0.2
        const rewardsDuration = duration / (data.rewards?.length || 1)

        this.rewardData.attribute.tp_milestone = data.attribute.tp_milestone
        this.rewardData.attribute.tp_new = data.attribute.tp_from
        this.rewardData.attribute.value_from = data.attribute.value_from
        this.rewardData.attribute.value_next = data.attribute.value_from + 1
        this.rewardData.bonus = data.bonus

        const gsaper1 = gsap.to(this.rewardData, {
          callbackScope: this,
          duration: duration + starsDuration,
          ease: 'linear',
          stars: data.stars
        })

        const gsaper2 = gsap.to(this.rewardData.score, {
          callbackScope: this,
          duration,
          delay,
          base: data.score.base,
          total: data.score.total,
          bonuses: data.score.bonuses
        })

        delay += duration
        this.gsapes.push(gsaper1)
        this.gsapes.push(gsaper2)
        /*
         * gsap.to(this.rewardData.subscription, {
         *     callbackScope: this,
         *     duration,
         *     active: data.subscription.active,
         *     tp: data.subscription.tp
         * })
         */

        const newMilestone = data.attribute.tp_milestone_new ??
                        data.attribute.tp_milestone

        const attributeUp = data.attribute.value_from + 1 !== data.attribute.value_next
        const newValue = attributeUp ?
          this.rewardData.attribute.tp_milestone :
          data.attribute.tp_new

        const gsap3 = gsap.to(this.rewardData.attribute, {
          callbackScope: this,
          duration,
          delay,
          tp_new: newValue,
          onComplete: () => {

            if (!attributeUp) return

            this.rewardData.attribute.tp_new = 0
            this.rewardData.attribute.tp_milestone = newMilestone
            this.rewardData.attribute.value_from = data.attribute.value_next - 1
            this.rewardData.attribute.value_next = data.attribute.value_next

            gsap.to(this.rewardData.attribute, {
              callbackScope: this,
              duration,
              delay: 0.2,
              tp_new: data.attribute.tp_new
            })

          }
        })
        this.gsapes.push(gsap3)
        // toto davame, ked je vyssi atribut, lebo potrebujeme to dat neskor dalsie
        if (attributeUp) delay += (duration + 0.2)

        delay += duration
        this.rewardData.energy.value_new_game = data.energy.value_new_game
        this.rewardData.energy.available = data.energy.available

        let index = 0

        gsap.timeline({
          duration: rewardsDuration,
          repeat: data.rewards?.length ?? 1,
          delay,
          onRepeat: () => {

            if (data?.rewards[index]) {

              this.rewardData.rewards.push(data.rewards[index])

            }
            index += 1

          },
          onComplete: () => {

            if (requestManager.isFirstTrainingTutorial()) {

              this.showTutorialRewards = true

            }

          }
        })

      }
    }
  },
  methods: {
    onButtonClickPlayAgain() {

      trainingResultsState().showResults = false

      if (this.gsapes.length > 0) {

        this.gsapes.forEach((gg: gsap.core.Tween) => gg.kill())
        this.gsapes = []

      }
      /*
       * TODO: reset game
       * Create dark overlay
       */
      // eslint-disable-next-line
      // @ts-ignore
      getActivePinia()?._s.forEach(s => s.$reset())
      loadingState().showLoading = false
      blackOverlayState().showBlackScreen = true

      audioManager.stopAllAudio()
      trainingTasks.reset()
      timeManager.reset()

      // Get init data
      requestManager.sendInitRequest(
        (data: ResponseInit) => {

          const dataFromRequest: ResponseInit = data

          // trening
          if (dataFromRequest.trainingData) {

            trainingManager.setUpFromData(dataFromRequest.trainingData)

          }

          trainingTasks.initTraining()

          playersManager.players = dataFromRequest.players

          // Reset vsetkych veci - core

          // TODO: Reset game
          corePhasesManager.reset()
          // cameraManager.setTweenSettingsForStates(trainingConfig.tweenSettingsForCameraStates)
          // Corephase reset nezabudnut
          requestManager.sendUpdateParamsRequest(() => {

            game.restart()
            endManager.reset()
            audioHelper.reset()
            finishCamera.reset()

            /*
             * toto realne len vypne loading teraz netreba
             * window.dispatchEvent(new CustomEvent(CustomEvents.gameStart))
             */
            player.updateCameraConfigOnStart(CameraStates.intro)
            disciplinePhasesManager.reset()
            corePhasesManager.startFirstPhase()

            // remove overlay
            blackOverlayState().showBlackScreen = false

          })

        },
        trainingManager.retry
      )

    },
    onButtonClickContinue() {

      console.log('clicked to continue')
      trainingResultsState().showResults = false
      disciplinePhasesManager.actualPhase = DisciplinePhases.finish
      corePhasesManager.setNextPhase()

    },
    isNewHighScore() {

      return trainingManager.isNewHighScore()

    },
    onTutorialRewardsButtonContinue() {

      this.showTutorialRewards = false
      waitingState().$patch({
        isWaiting: false,
        trainingButtonsDisabled: false
      })

    }
  }
})

</script>

<style lang="less" scoped>
.training-results {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &.mobile-overlay {
        position: fixed;
    }
}
</style>
