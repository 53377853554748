import {
  LungeStates,
  type DisciplinePhaseManager,
  Sides
} from '../../types'
import {
  MobileDetector,
  gsap,
  modes
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/athlete/player'
import { lungeConfig } from '@/app/config'
import {
  actionButtonState,
  gamePhaseState,
  inputsState,
  lungeState,
  movementButtonsState,
  tutorialState
} from '@/stores'
import { endManager } from '@/app/EndManager'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** speed lock */
  public speedLockActive = false

  /** koeficient na nasobenie rychlosti a casov pri speedLocku */
  public speedLockCoef = 1

  /** Ci je dokoncena transformacia kamery v speed locku */
  public speedLockCameraTransformationComplete = false

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** Ci je aktivna faza */
  public isActive = false

  /** ci zobrazit lunge info ui */
  public showLungeInfo = false

  /** na kolko je naplneny lunge info bar */
  public lungeInfoFill = 0

  /** Ci je kamera z boku */
  public cameraFromSide = false

  /** lunge color */
  private lungeColor = ''

  /** message type lunge */
  private lungeMessageType = ''

  /** Pocitadlo frameov pre lunge */
  private frameCounterLunge = 0


  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.lungeInfoFill = 0
    console.warn('game phase started')
    this.isActive = true

  }

  /**
   * Zobrazenie bezeckeho UI (pri rozbehu este nebude)
   */
  public showRunningUi(): void {

    gamePhaseState().showBar = true
    movementButtonsState().isActive = true
    this.showMobileButtons()

  }

  /**
   * Zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    actionButtonState().showJoystick = true
    inputsState().disabled = false

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.setLungeInfoFill()

  }

  /**
   * Inputy pri behu
   */
  public handleInputs(side: Sides | undefined): void {

    if (!this.isActive) return
    player.velocityManager.handleInputs(side)
    if ((modes.isTutorial()) || side !== undefined) return
    player.handleLunge()

  }

  /**
   * Nastavi hodnotu pre lunge info bar
   */
  private setLungeInfoFill(): void {

    if (!this.showLungeInfo) return

    this.frameCounterLunge += 1

    if (player.lungeState === LungeStates.forward) {

      this.lungeInfoFill = player.lungeActualFrame / player.getLungeMaxFrames() * 100

    }
    if (player.lungeState === LungeStates.backward) {

      this.lungeInfoFill = (1 - (player.lungeActualFrame / player.getLungeMaxFrames())) * 100

    }

    const { worldEnvLinesManager } = player
    const meters = Math.round(worldEnvLinesManager.lungeOffset / worldEnvLinesManager.oneMeterInPercent * 100) / 100

    this.setLungeInfoData(meters)
    lungeState().$patch({
      showLungeInfo: this.showLungeInfo,
      text: `+${meters} m`,
      fill: this.lungeInfoFill,
      color: this.lungeColor,
      messageType: '',
      showInfo: this.frameCounterLunge > 10 ? true : false
    })

  }

  /**
   * Nastavenie dat pre lungeInfo podla metrov
   * @param meters - number
   */
  private setLungeInfoData(meters: number): void {

    if (meters <= ((lungeConfig.lungeOffsetMax - lungeConfig.lungeOffsetMin) * 0.5)) {

      this.lungeColor = 'red'
      if ([LungeStates.forward, LungeStates.none].includes(player.lungeState)) {

        this.lungeMessageType = 'too-late'

      } else {

        this.lungeMessageType = 'too-early'

      }

    } else if (meters <= ((lungeConfig.lungeOffsetMax - lungeConfig.lungeOffsetMin) * 0.75)) {

      this.lungeColor = 'orange'
      this.lungeMessageType = 'good'

    } else if (meters <= ((lungeConfig.lungeOffsetMax - lungeConfig.lungeOffsetMin) * 0.99)) {

      this.lungeColor = 'yellow'
      this.lungeMessageType = 'excellent'

    } else {

      this.lungeColor = 'green'
      this.lungeMessageType = 'perfect'

    }

  }

  /**
   * zapnutie speed locku
   */
  public activateSpeedLock(): void {

    tutorialState().showInstructionBox = false
    console.warn('SPEED LOCK')
    gamePhaseState().showBar = false
    this.showLungeInfo = true
    this.speedLockActive = true

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.isActive = false
    this.speedLockCameraTransformationComplete = false
    console.warn('game phase ended')
    this.speedLockActive = false
    this.callbackEnd()

    const { worldEnvLinesManager } = player
    const meters = Math.round(worldEnvLinesManager.lungeOffset / worldEnvLinesManager.oneMeterInPercent * 100) / 100
    lungeState().$patch({
      showLungeInfo: this.showLungeInfo,
      text: `+${meters} m`,
      fill: this.lungeInfoFill,
      color: this.lungeColor,
      messageType: this.lungeMessageType,
      showInfo: false
    })
    if (this.lungeMessageType === 'perfect') endManager.perfectLunge = true
    this.showLungeInfo = false

    gsap.to({}, {
      onComplete: () => {

        lungeState().$patch({
          showLungeInfo: this.showLungeInfo,
          text: '',
          fill: 0,
          color: '',
          messageType: '',
          showInfo: false
        })

      },
      duration: 2
    })

    this.frameCounterLunge = 0

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
