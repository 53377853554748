/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionFourth = 'startSectionFourth',
  startSectionFifth = 'startSectionFifth',
  startSectionSixth = 'startSectionSixth',
  startSectionSeventh = 'startSectionSeventh',
  startSectionEight = 'startSectionEight',
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  failedStart = 'failedStart',
  goodStart = 'goodStart',
  beforeFirstFinish = 'beforeFirstFinish',
  firstFinish = 'firstFinish',
  goodFinish = 'goodFinish',
  okFinish = 'okFinish',
  badFinish = 'badFinish',
  autoLunge = 'autoLunge',
  missed = 'miseed',
  missedFixed = 'missedFixed',
  tooBad = 'tooBad',
  tooBadFixed = 'tooBadFixed',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  start = 'tutorialTask15-1',
  goodPaddles = 'tutorialTask15-2',
  lunge = 'tutorialTask15-3',
}
