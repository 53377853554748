import { defineStore } from 'pinia'

export interface MovementButtonsState {
  isActive: boolean
}

const initialState = (): MovementButtonsState => ({
  isActive: false
})

export const movementButtonsState = defineStore('movementButtonsState', {
  state: initialState,
})
