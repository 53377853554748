import { cameraConfig } from '@/app/config'
import {
  cameraManager,
  gsap,
  THREE
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { DisciplinePhases } from '@/app/types'
import { worldEnv } from '@/app/entities/env/WorldEnv'
/**
 * Kamera v cieli
 */
export class FinishCamera {

  /** Ci sa ma zobrazit specialna kamera v cieli */
  public isActive = false

  /** Frame counter pre ciel */
  private frameCounterFinish = 0

  /** Aktualne percento na krivke */
  private actualPercent = 0

  /** timeline pre kameru */
  private timelineCamera!: gsap.core.Timeline

  /** Pomocny vektor pre kameru */
  private cameraVector = new THREE.Vector3()

  /**
   * Vytvorenie veci pre kameru
   */
  public create(): void {

    this.cameraVector.copy(cameraConfig.finish.positions[0][0])
    const endValue = cameraConfig.finish.positions[0][2]
    const endValue2 = cameraConfig.finish.positions[1][2]
    this.timelineCamera = gsap.timeline().to(
      this.cameraVector,
      {
        x: endValue.x,
        y: endValue.y,
        z: endValue.z,
        duration: 1,
        ease: 'power1.inOut'
      }

    ).to(
      this.cameraVector,
      {
        x: endValue2.x,
        y: endValue2.y,
        z: endValue2.z,
        duration: 1,
        ease: 'power1.inOut'
      }
    ).pause()

  }

  /**
   * Aktualizovanie veci kazdy frame
   */
  public update(): void {

    if (disciplinePhasesManager.actualPhase !== DisciplinePhases.finish) return

    if (!this.isActive) {

      this.frameCounterFinish += 1
      if (this.frameCounterFinish >= cameraConfig.finish.delayFrames) {

        this.isActive = true
        worldEnv.setVisibilityFinishWall(false)

      }

    }

    // toto chceme spravit kludne hned, ako sa nastavi ze je aktivne
    if (this.isActive && this.actualPercent < 1) {

      this.actualPercent += cameraConfig.finish.addValuePerFrame
      if (this.actualPercent > 1) this.actualPercent = 1
      this.timelineCamera.progress(this.actualPercent)
      cameraManager.changeIdeals(this.cameraVector, undefined, undefined, undefined, true)

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.isActive = false
    this.frameCounterFinish = 0
    this.actualPercent
    this.cameraVector.copy(cameraConfig.finish.positions[0][0])

  }

}

export const finishCamera = new FinishCamera()
