<template>
  <div
    class="under-menu-component"
    :class="[isMobile() ? 'mobile-under-menu-component' : '']"
    :style="transformCoef"
  >
    <div class="component-item">
      <time-keeper-component />
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import {
  PositionBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

// Game Components
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'
import { lapPositionState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UnderMenuComponent',
  components: {
    TimeKeeperComponent,
    PositionBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      lapPositionState: lapPositionState(),
    }

  },
  computed: {
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': '100% 0'
      }

    }
  },
})

</script>

<style lang="less" scoped>

.under-menu-component{
    position: absolute;
    right: 1%;
    top: 14%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.mobile-under-menu-component {
        top: 14%;
    }

    .component-item {
        position: relative;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .position {
      text-shadow: 0px 2px 2px #000;
      font-family: TTLakes;
      font-size: 32px;
      font-weight: 600;
      font-stretch: condensed;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .position-box {
      width: 220px;
    }

}

</style>
