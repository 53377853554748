<template>
  <Teleport to="#bottom-hud-teleport">
    <div
      v-if="lapPositionState.showPosition"
      class="bottom-hud-container"
      :style="{transform: `scale(${scaleCoef})`}"
    >
      <div class="bottom-hud-wrapper">
        <div
          class="left-part"
          :class="{green: lapPositionState.playerOffset >= 0}"
        >
          <div class="text">
            <span v-if="lapPositionState.playerOffset > 0">
              +
            </span>
            {{ lapPositionState.playerOffset }}m
          </div>
        </div>
        <div class="right-part">
          <div
            class="text"
            :class="'text-' + lapPositionState.position"
          >
            {{ lapPositionState.position }}
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { lapPositionState } from '@/stores/index'

export default defineComponent({
  name: 'BottomHUD',
  mixins: [WindowAspect],
  data() {

    return {
      lapPositionState: lapPositionState(),
    }

  }
})
/* eslint-disable max-len */
</script>

<style lang="less" scoped>
  .bottom-hud-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform-origin: bottom;

    .bottom-hud-wrapper {
      display: flex;
      gap: 120px;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 110px;

      .text {
        background: linear-gradient(to top, #feb942, #fff196), linear-gradient(to bottom, #fbb642, #fbb642);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .left-part {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 233px;
        height: 70px;
        background: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/bg-distance2.png');
        background-size: contain;
        background-repeat: no-repeat;
        .text {
          font-size: 40px;
          font-weight: 600;
          font-stretch: condensed;
          background-image: linear-gradient(to bottom, #ff6344, #c1290b);
          -webkit-text-stroke: 1px rgba(132, 22, 0, 0.76);
        }

        &.green {
          .text {
            background-image: linear-gradient(to bottom, #0ee7cc, #108b7c);
          }
        }
      }
      .right-part {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 233px;
        height: 70px;
        background: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/bg-position2.png');
        background-size: contain;
        background-repeat: no-repeat;

        .text {
          font-size: 50px;
          font-weight: 600;
          font-stretch: condensed;
          background-image: linear-gradient(to bottom, #fffefd, #ffffff);
          -webkit-text-stroke: 1px rgba(112, 71, 0, 0.76);

          &.text-1st {
            background-image: linear-gradient(to bottom, #ffd58c, #cf8609);
          }
          &.text-2nd {
            background-image: linear-gradient(to bottom, #ffffff, #a5a5a5);
          }
          &.text-3rd {
            background-image: linear-gradient(to bottom, #ffceb3, #ee8168);
          }
        }

      }
    }
  }
</style>