import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.balance1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.balance2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.finishL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.finishR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.toEmotionL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.toEmotionR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy3]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy4]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.paddlingL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.paddlingR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prestart]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.readyL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.readyR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sad1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sad2]: {
    loop: true,
    starting: false
  },

}
