<template>
  <game-kayak-component />
</template>

<script lang="ts">
import GameKayakComponent from '@/components/GameKayakComponent.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    GameKayakComponent
  },
})

</script>
